import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom'

const Footer = () => {
  const year = new Date().getFullYear();
  const [hid, changeHide] = useState(false)
  useEffect(() => {
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
    // const isIOSChrome = winNav.userAgent.match("CriOS");

    if (
      isChromium !== null
      && typeof isChromium !== 'undefined'
      && vendorName === 'Google Inc.'
      && isOpera === false
      && isIEedge === false
    ) {
      changeHide(true)
    }
  })
  return (
    <footer className="footer">
      <div
        className="footer-info-text"
        style={{
          display: hid ? 'none' : 'block',
        }}
      >
        <div
          className="user-bro-text"
        >
          For the best experience and performance, we recommend using the latest version of Google Chrome and using a desktop or laptop device.
          <button
            type="button"
            style={{
              marginLeft: '30px',
            }}
            onClick={() => changeHide(true)}
          >
            Dismiss
          </button>
        </div>
      </div>
      <div className="logo-copyright">
        <div className="combine-both disp-flex align-center">
          <div className="logo-data">
            <a
              href="https://www.innoplexus.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="disp-inline-flex center"
            >
              <span alt="Innoplexus Logo" className="footer-logo" />
            </a>
          </div>
          <div className="link-url">
            <Link
              // href="https://ontosight.ai/privacypolicy"
              // target="_blank"
              to="/privacypolicy"
              target="_blank"
              className="footer-link-url"
            >
              <span>Privacy policy</span>
            </Link>
            <Link
              // href="https://ontosight.ai/userpolicy"
              // target="_blank"
              to="/userpolicy"
              target="_blank"
              className="footer-link-url"
            >
              <span>User policy</span>
            </Link>
          </div>
          <div className="link-url link-connect">
            <a
              href="https://www.parexel.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link-url"
            >
              <span>www.parexel.com</span>
            </a>
            <span className="divider" />
            <a
              href="https://www.innoplexus.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link-url"
            >
              <span>www.innoplexus.com</span>
            </a>
          </div>
        </div>
        <div className="copyright-data">
          <span>
            {`Copyright © ${year} - `}
            <a
              href="http://www.innoplexus.com"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link-text"
            >
              <span>Innoplexus AG</span>
            </a>
            {/* <span>All Rights Reserved.</span> */}
          </span>
        </div>
        {/* <div className="query-contact">
          For any queries please contact us:
          <span className="footer-link-email">support.landscape@innoplexus.com</span>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
