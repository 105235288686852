import { Tooltip } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { isSponsor, setAnalytics } from '../../../utils';
import { createBookmarkAction, deleteBookmarkAction } from './logic';

const Bookmark = ({ ...props }) => {
  const dispatch = useDispatch();

  const setFavourite = (dataset_type, doc_id) => {
    dispatch(createBookmarkAction({
      body: {
        type: dataset_type,
        id: doc_id,
      },
    }))
    const params = {
      type: dataset_type,
      id: doc_id,
      action: 'created favourite',
    }
    setAnalytics('favourite', 'data', params)
  };

  const removeFavourite = (id) => {
    dispatch(deleteBookmarkAction({
      params: { bookmark_id: id },
    }))
    const params = {
      bookmark_id: id,
      action: 'deleted favourite',
    }
    setAnalytics('unFavourite', 'data', params)
  };

  if (props.bookmark_id && props.bookmark_id !== '') {
    return (
      <Tooltip title="Mark / un-mark favorite">
        <div
          className="fav-img fav-img-active"
          role="button"
          tabIndex={0}
          aria-label="Mark / un-mark favorite"
          onClick={() => {
            if (props.inplace) {
              props.onBookmarkChange(props.id, 'delete')
            }
            removeFavourite(props.bookmark_id)
          }}
        >&nbsp;
        </div>
      </Tooltip>
    );
  }
  if (!props.bookmark_id) {
    return (
      <Tooltip title="Mark / un-mark favorite">
        <div
          className="fav-img"
          role="button"
          tabIndex={0}
          aria-label="Mark / un-mark favorite"
          onClick={() => {
            if (props.inplace) {
              props.onBookmarkChange(props.id, 'create')
            }
            setFavourite(props.type, props.id)
          }}
        >&nbsp;
        </div>
      </Tooltip>
    );
  }

  const onFavClick = () => {
    if (props.inplace) {
      props.onBookmarkChange(props.id, 'create')
    }
    setFavourite(props.type, props.id)
  }

  return (
    <Tooltip title="Mark / un-mark favorite">
      <div
        aria-label="Mark / un-mark favorite"
        className={`fav-img ${isSponsor() ? 'disabled' : ''}`}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (isSponsor() === false) {
            onFavClick()
          }
        }}
      >&nbsp;
      </div>
    </Tooltip>
  );
}

export default Bookmark;
