import {
  map, takeUntil, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
// import auth0 from 'auth0-js'
import { apiCall } from '../../../utils';

// const webAuth = new auth0.WebAuth({
//   domain: process.env.auth0.domain,
//   clientID: process.env.auth0.clientId,
// })

const LOGOUT = 'LOGOUT';
const LOGOUT_CANCEL = 'LOGOUT_CANCEL';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
  status: 0,
};

export const logoutFetchAction = (payload) => ({
  type: LOGOUT,
  payload,
});

// const logoutFetchSuccess = (payload) => (

//   webAuth.logout({
//     returnTo: process.env.mainRoute,
//     clientID: process.env.auth0.clientId,
//   })
//   return{
//   type: LOGOUT_SUCCESS,
//   payload,
//   showNotification: true,
// });

const logoutFetchSuccess = ({ response = {} }) => {
  // webAuth.logout({
  //   returnTo: 'http://localhost:8081',
  //   clientID: process.env.auth0.clientId,
  // })
  return {
    type: LOGOUT_SUCCESS,
    payload: response,
  }
}

export const logoutFetchCancel = (payload) => ({
  type: LOGOUT_CANCEL,
  payload,
});

export const logoutFetchEpic = (actions$) => actions$.pipe(
  ofType(LOGOUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/auth/logout?${action.payload}`, 'DELETE')).pipe(
    map((res) => logoutFetchSuccess(res)),
    takeUntil(actions$.pipe(ofType(LOGOUT_CANCEL))),
    catchError((err) => of({
      type: LOGOUT_FAILURE,
      payload: err,
    })),
  )),
);

// Auth reducer updates both logout and logout
export function logoutFetchReducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false,
        status: 0,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
        status: action.payload.status || 200,
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false,
        status: action.payload.status || 400,
      };
    }
    default:
      return state;
  }
}
