import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { detect } from 'detect-browser';
import { parse, stringify } from 'query-string';
import {
  Button,
  Modal, Popover, Radio, Tooltip,
} from 'antd';
import {
  InfoCircleOutlined, DownCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Logout from '../Logout';
import Loader from '../../components/Loader';
import LazyLoadDiv from '../../components/LazyLoadDiv/index';
import {
  getCookie, checkBrowserSupport, getFormattedDate, getFormattedTime, isSponsor,
} from '../../../utils';
import {
  getProjectCountsAction,
  getNotificationsAction,
  getNotificationsMoreAction,
  updateNotificationAction,
  updateNotificationResetAction,
  getTaggedCommentsAction,
  getAccessPermissionsAction,
} from '../../../app/container/Search/logic';
import {
  getProjectsDataAction,
} from '../../../app/container/MyProjects/logic';
import OutsideClick from '../../components/OutsideClick';
import SearchBar from '../../../app/container/SearchBar';
import RenderAdvanceSearchModal from '../../../app/components/RenderAdvanceSearchModal';
import TaggedComments from '../TaggedComments';
import { deleteNotificationAction } from './logic';

const Header = () => {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [countsData, setCountsData] = useState({});
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationTotal, setNotificationTotal] = useState(0);
  const [from, setFrom] = useState(1);
  const dispatch = useDispatch()
  const session = useSelector((state) => state.session);
  const projectCountsData = useSelector((state) => state.projectCountsData);
  const copyProjectData = useSelector((state) => state.copyProjectData);
  const addProjectDetails = useSelector((store) => store.addProjectDetails);
  const notificationsData = useSelector((state) => state.notificationsData);
  const taggedCommentsData = useSelector((store) => store.taggedCommentsData)
  const updateNotificationResp = useSelector((state) => state.updateNotificationResp);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const browser = detect();
  const isBrowserSupported = checkBrowserSupport(browser);
  const [fieldToSearch, setFieldToSearch] = useState('');
  const [valueToCheck, setValueToCheck] = useState(true);
  const [showAdvanceSearchModal, setAdvanceSearchModal] = useState(false)
  const [isTaggedCommentModalOpen, setIsTaggedCommentModalOpen] = useState(false)
  const deleteNotificationResponse = useSelector((store) => store.deleteNotificationResponse)
  useEffect(() => {
    if (deleteNotificationResponse.flag) {
      dispatch(getNotificationsAction({
        from: 1,
        size: 10,
      }))
    }
  }, [JSON.stringify(deleteNotificationResponse)])

  const newData = {
    profile_fields: [{
      field: '', compare: '', value: '', operator: '',
    }],
    race_ethnicity: [{
      field: '', type: '', compare: '', value: '', operator: '',
    }],
    age_sex: [{
      field: '', compare: '', value: '', operator: '',
    }],
    employment_by_insurance: [{
      field: '', type: '', label: '', compare: '', value: '', operator: '',
    }],
    income_by_insurance: [{
      field: '', compare: '', value: '', operator: '',
    }],
    studies: [{
      field: '', compare: '', value: '', operator: '',
    }],
    cls_kol_score: [{
      field: '', compare: '', value: '', operator: '',
    }],
    sources: [],
    indications: [],
    alliance_type: [],
    center_type: [],
    therapeutic_area: [],
    total_number_of_pis: [{
      field: '', compare: '', value: '', operator: '',
    }],
    tier: [],
    country: [],
    emerging_tag: [],
    cls_kol_score_class: [],
    census_available_countries: ['United States'],
    landscape_id: [],
  }

  const [filterData, setData] = useState(newData);

  useEffect(() => {
    if (localStorage.getItem('all_advance_search') !== 'undefined' || localStorage.getItem('all_advance_search') !== null
    ) {
      setData(JSON.parse(localStorage.getItem('all_advance_search')))
    } else {
      setData(newData)
    }
  }, [localStorage.getItem('all_advance_search')])

  const onFieldChange = (e) => {
    setFieldToSearch(e.target.value);
    setData(newData)
    const params = {
      ...parse(location.search),
      searchfield: e.target.value,
    };
    history.replace(`${location.pathname}?${stringify(params)}`);
  };
  useEffect(() => {
    if (parse(location.search).searchfield) {
      setFieldToSearch(parse(location.search).searchfield)
    } else {
      setFieldToSearch('all')
    }
  }, [parse(location.search)])
  useEffect(() => {
    if (!session) {
      setDisplayDropdown(false);
    }
    if (session) {
      dispatch(getProjectCountsAction())
      if (getCookie('permissions') && getCookie('permissions').length) {
        dispatch(getAccessPermissionsAction(
          {
            roles: getCookie('permissions').toString(),
          },
        ))
      }
      dispatch(getProjectsDataAction())
      dispatch(getNotificationsAction({
        from: 1,
        size: 10,
      }))
      dispatch(getTaggedCommentsAction({
        user_id: getCookie('userName'),
        from: 1,
        size: 10,
      }))
    }
  }, [session]);

  useEffect(() => {
    if (addProjectDetails.flag) {
      dispatch(getProjectCountsAction())
      dispatch(getProjectsDataAction())
    }
  }, [JSON.stringify(addProjectDetails)]);

  useEffect(() => {
    if (copyProjectData.flag) {
      dispatch(getProjectCountsAction())
      dispatch(getProjectsDataAction())
    }
  }, [JSON.stringify(copyProjectData)]);

  useEffect(() => {
    if (projectCountsData.flag) {
      setCountsData(projectCountsData.data);
    }
  }, [JSON.stringify(projectCountsData)]);

  useEffect(() => {
    if (notificationsData.flag) {
      setNotificationsList(notificationsData.data.data.all_notifications_list);
      setNotificationTotal(notificationsData.data.data.totalCount);
    }
  }, [JSON.stringify(notificationsData)]);

  useEffect(() => {
    if (updateNotificationResp.flag) {
      setFrom(1);
      dispatch(getNotificationsAction({
        from: 1,
        size: 10,
      }));
      dispatch(updateNotificationResetAction());
    }
  }, [JSON.stringify(updateNotificationResp)]);

  const redirectToPage = (redirectTo, isDisabled) => {
    if (!isDisabled) {
      history.push(redirectTo);
    }
  }

  const renderLoginRegisterMenus = () => (
    <div className="header-menu-right">
      {location.pathname !== '/login' ? (
        <Link to="/login" className="login-link link">
          Login
        </Link>
      ) : (
        <Link to="/" className="login-link link">
          Home
        </Link>
      )}
      {location.pathname !== '/register' ? null : (
        <Link to="/" className="login-link link">
          Home
        </Link>
      )}
    </div>
  );
  const showInfoModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [finalFilteredData, setFinalFilteredData] = useState({});

  const getProjectPageName = () => {
    switch (location.pathname) {
      case '/projects':
        return 'My Projects';

      case '/projects/createproject':
        if (parse(location.search).project_id) {
          return 'Edit Project';
        }
        return 'Create Project';

      case '/projects/projectdetails':
        if (parse(location.search).country_summary === 'true') {
          return 'Country Summary';
        }
        return 'Project Details';

      default:
        return 'My Projects';
    }
  }

  const markNotificationRead = (notification) => {
    if (!notification.is_read) {
      dispatch(updateNotificationAction({
        notification_id: [
          notification.notification_id,
        ],
      }))
    }
  }

  const loadMoreNotifications = () => {
    setFrom(from + 1);
    dispatch(getNotificationsMoreAction({
      from: from + 1,
      size: 10,
    }));
  }

  const onAdvanceSearchClick = () => {
    setAdvanceSearchModal(true)
  }

  const onDeleteNotificationCick = (idd) => {
    dispatch(deleteNotificationAction(
      {
        body: {
          notification_id: [idd],
        },
      },
    ))
  }

  const renderNotifications = () => {
    if (notificationsData.flag && notificationsList.length > 0) {
      return (
        <Loader loading={deleteNotificationResponse.loading} error={deleteNotificationResponse.error}>
          <div className="notification-row">
            <LazyLoadDiv
              className="scrollbar"
              id="notifications-list"
              total={notificationTotal}
              currentTotal={(notificationsList || []).length}
              loadMoreRows={() => loadMoreNotifications()}
            >
              {notificationsList.map((notification, index) => (
                <>
                  <div
                    className={`notification-row-value ${notification.is_read ? 'notification-read' : 'notification-unread'}`}
                    key={index}
                    role="presentation"
                    onClick={() => markNotificationRead(notification)}
                  >
                    <div className="notification-row-value-title del-icon">
                      <div className="notification-title">
                        {notification.notification_title}
                      </div>
                      <div className="del-icon">
                        <Button onClick={() => onDeleteNotificationCick(notification.notification_id)}>
                          <DeleteOutlined />
                        </Button>
                      </div>
                    </div>
                    <div className="notification-row-value-desc">{notification.notification_msg}</div>
                    <div className="notification-row-value-time">
                      <div className="date">{getFormattedDate(notification.created_on)}</div>
                      <div className="time">{getFormattedTime(notification.created_on)}</div>
                    </div>
                  </div>
                </>
              ))}
            </LazyLoadDiv>
          </div>
        </Loader>
      )
    }
    if (notificationsData.flag && notificationsList.length === 0) {
      return (
        <div className="no-notifications-msg">
          No notifications received yet.
        </div>
      )
    }
    return null;
  };

  return session ? (
    <div className="header-section">
      <div className="navbar landing-header">
        <div className="navbar-left-sec">
          <div
            className="logo-header"
            role="presentation"
            onClick={() => {
              history.push(process.env.mainRoute);
            }}
          >
            <div>
              <div className="logo-parexel" />
            </div>
            <div>
              <div className="logo-inno" />
            </div>
          </div>
          {session ? (
            <div className="new-nav-section">
              <div className="secondary-navbar">
                <div className="secondary-navbar-group-container">
                  <div className="secondary-navbar-group-left">
                    <Link to="/search" className={`secondary-navbar-group-left-item secondary-navbar-group-link ${(location.pathname.indexOf('/search') > -1 || (location.pathname.indexOf('/projects') > -1 && parse(location.search).shared_project === 'false')) && !(location.pathname.indexOf('/countryprofile') > -1) ? 'active' : isSponsor() ? 'disabled' : ''}`}>
                      Search
                    </Link>
                    <Link to="/projects" className={`secondary-navbar-group-left-item secondary-navbar-group-link ${location.pathname.indexOf('/projects') > -1 && !parse(location.search).shared_project ? 'active' : isSponsor() ? 'disabled' : ''}`}>
                      My Projects ({countsData.my_project_count})
                    </Link>
                    <Link to="/sharedprojects" className={`secondary-navbar-group-left-item secondary-navbar-group-link ${location.pathname.indexOf('/sharedprojects') > -1 || (location.pathname.indexOf('/projects') > -1 && parse(location.search).shared_project === 'true') ? 'active' : ''}`}>
                      Shared with me ({countsData.shared_with_me_count})
                    </Link>
                    <Link to="/workspace" className={`secondary-navbar-group-left-item secondary-navbar-group-link ${location.pathname.indexOf('/workspace') > -1 ? 'active' : isSponsor() ? 'disabled' : ''}`}>
                      My Workspace
                    </Link>
                    <Link to="/countries" className={`secondary-navbar-group-left-item secondary-navbar-group-link ${location.pathname.indexOf('/countries') > -1 || location.pathname.indexOf('/countryprofile') > -1 ? 'active' : isSponsor() ? 'disabled' : ''}`}>
                      Countries
                    </Link>
                    {
                     getCookie('permissions').indexOf('landscape_innoplexus_super_admin') !== -1 ? (

                       <Link to="/inviteuser" className={`secondary-navbar-group-left-item secondary-navbar-group-link ${location.pathname.indexOf('/inviteuser') > -1 ? 'active' : ''}`}>
                         Invite User
                       </Link>
                     ) : ''
                    }
                  </div>
                  <div className="secondary-navbar-group-right" />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {isBrowserSupported ? (
          session ? (
            <div className="navbar-group-container">
              <div role="presentation" className="tagged-comments" onClick={() => setIsTaggedCommentModalOpen(true)}><div className="comments-icon" /><div className="notifications-count active zindex">{taggedCommentsData?.data?.total}</div></div>
              <Popover
                overlayClassName="notification-popover"
                placement="bottom"
                title="Notification"
                content={(
                  <Loader loading={notificationsData.loading || updateNotificationResp.loading} error={notificationsData.error || updateNotificationResp.error}>
                    {renderNotifications()}
                  </Loader>
                )}
                trigger="click"
                destroyTooltipOnHide
              >
                <div className="bell-notification">
                  <div className="bell-cover">
                    <Tooltip title="Notifications">
                      <div className="bell-icon">
                        <div className="bell-icon-img" />
                      </div>
                    </Tooltip>
                    {notificationsData.flag && notificationsData.data.data
                      ? (
                        <div className="notifications-count active zindex">{notificationsData.data.data.unread_notificationCount}</div>
                      )
                      : null}
                  </div>
                </div>
              </Popover>
              <div className="navbar-group-right">
                <div className="navbar-group-right-item user-text">
                  {getCookie('name') ? (
                    <div
                      className="default-user-img"
                      role="presentation"
                      onClick={() => {
                        setDisplayDropdown(!displayDropdown);
                      }}
                    >
                      <Tooltip title="User">
                        <div className="user-img" />
                      </Tooltip>
                      <div className="user-item text-elips" title={getCookie('name')}>
                        {getCookie('name')}
                      </div>
                      &nbsp;
                    </div>
                  ) : (
                    <div
                      className="default-user-img"
                      role="presentation"
                      onClick={() => {
                        setDisplayDropdown(!displayDropdown);
                      }}
                    >
                      <Tooltip title="User">
                        <div className="user-img" />
                      </Tooltip>
                      <div className="user-item text-elips" title="Guest">
                        Guest
                      </div>
                      &nbsp;
                    </div>
                  )}
                </div>
                {displayDropdown ? (
                  <OutsideClick
                    ignoreClickWithinClass="default-user-img"
                    onClickOutside={() => {
                      setDisplayDropdown(false);
                    }}
                  >
                    <div className="user-dropdown">
                      <div className="mobile-user-item text-elips" title={getCookie('name')}>
                        {getCookie('name')}
                      </div>
                      <Logout />
                    </div>
                  </OutsideClick>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <div className="desktop-view">{renderLoginRegisterMenus()}</div>
              <div className="mobile-view">
                <div className="mob-head-btn">
                  <button
                    className="mobile-dropdown-btn"
                    type="button"
                    onClick={() => setOpenMenu(!openMenu)}
                  >
                    U
                  </button>
                  {openMenu ? (
                    <OutsideClick onClickOutside={() => setOpenMenu(false)}>
                      <div
                        className="menu-in-dropdown"
                        role="presentation"
                        onClick={() => setOpenMenu(false)}
                      >
                        {renderLoginRegisterMenus()}
                      </div>
                    </OutsideClick>
                  ) : null}
                </div>
              </div>
            </>
          )
        ) : null}
      </div>
      {session && location.pathname !== '/search' && location.pathname !== '/projects/runquery' && location.pathname !== '/projects/runanalysis' && location.pathname !== '/projects/compareinvestigators' && location.pathname !== '/projects/comparesites' && location.pathname !== '/projects/compareanalysis' && location.pathname !== '/projects/savedanalysis' && location.pathname !== '/projects/savedproject' ? (
        <div className={`secondary-navbar-header ${location.pathname.indexOf('/projects') > -1 || location.pathname.indexOf('/sharedprojects') > -1 || location.pathname.indexOf('/workspace') > -1 || location.pathname.indexOf('/countries') > -1 || location.pathname.indexOf('/inviteuser') > -1 ? 'project-subheader' : ''}`}>
          <div className="combine-search-logo">
            <div className="landscape-logo">
              <div className="landscape-logo-img" />
            </div>
            {session && location.pathname.indexOf('/projects') > -1 ? (
              <div className="project-section">
                <div className="breadcrumbs">
                  <div className="breadcrumbs-inner">
                    <div
                      className="breadcrumbs-link active-link"
                      role="presentation"
                      onClick={() => {
                        if (parse(location.search).shared_project) {
                          redirectToPage('/sharedprojects', false)
                        } else {
                          redirectToPage('/projects', false)
                        }
                      }}
                    >
                      {parse(location.search).shared_project ? parse(location.search).shared_project === 'false' ? 'Search' : 'Shared With Me' : 'My Projects'}
                    </div>
                    {location.pathname !== '/projects'
                      ? (
                        <>
                          <div className="breadcrumbs-link">{getProjectPageName()}</div>
                        </>
                      )
                      : null}
                  </div>
                  {parse(location.search).country_summary === 'true' && (
                  <div className="country-summary-back-button">
                    <Button
                      className="summary-back-btn"
                      onClick={() => {
                        history.replace(`${location.pathname}?${stringify({ ...parse(location.search), country_summary: false })}`)
                      }}
                    >Back
                    </Button>
                  </div>
                  )}
                </div>
                <div className="info-section" onClick={showInfoModal} role="presentation">
                  <Tooltip title="View Information"><InfoCircleOutlined /></Tooltip>
                </div>
                <Modal
                  title=""
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  className="info-modal"
                >
                  <div className="project-info-modal">
                    <div className="title">Project</div>
                    <div className="value">Users are required to create a project for managing the feasibility study using different approaches (analysis) and subsequent investigator shortlisting. Within each project, users can create more than one analysis for shortlisting principal investigators.</div>
                    <div className="title">Analysis</div>
                    <div className="value">Users can create an analysis for each approach to shortlist investigators. Under each analysis, more than one query (set of filters) can be created. </div>
                    <div className="title">Query</div>
                    <div className="value">Users can create a query to define criteria for shortlisting investigators. Users can create multiple queries under one analysis/approach and define the order in which the queries need to be executed. Order of queries affect the analysis results; however, each query can also be run independently to assess the results of criteria defined in the query.</div>
                  </div>
                </Modal>
              </div>
            ) : location.pathname.indexOf('/sharedprojects') > -1
              ? (
                <div className="project-section">
                  <div className="breadcrumbs">
                    <div className="breadcrumbs-inner">
                      <div className="breadcrumbs-link active-link" role="presentation" onClick={() => redirectToPage('/sharedprojects', false)}>Shared With Me</div>
                    </div>
                  </div>
                </div>
              )
              : location.pathname.indexOf('/countries') > -1
                ? (
                  <div className="project-section">
                    <div className="breadcrumbs">
                      <div className="breadcrumbs-inner">

                        <div className="breadcrumbs-link active-link" role="presentation" onClick={() => redirectToPage('/countries', false)}>Countries</div>
                      </div>
                    </div>
                  </div>
                )
                : location.pathname.indexOf('/workspace') > -1
                  ? (
                    <div className="project-section">
                      <div className="breadcrumbs">
                        <div className="breadcrumbs-inner">

                          <div className="breadcrumbs-link active-link" role="presentation" onClick={() => redirectToPage('/workspace', false)}>My Workspace</div>
                        </div>
                      </div>
                    </div>
                  )
                  : getCookie('permissions').indexOf('landscape_innoplexus_super_admin') !== -1 && location.pathname.indexOf('/inviteuser') > -1
                    ? (
                      <div className="project-section">
                        <div className="breadcrumbs">
                          <div className="breadcrumbs-inner">

                            <div className="breadcrumbs-link active-link" role="presentation" onClick={() => redirectToPage('/inviteuser', false)}>Invite User</div>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className="search-bar-section" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="search-with-adv-s">
                          <SearchBar handleModal={{ flagName: 'Header' }} fieldToSearch={fieldToSearch} setValueToCheck={setValueToCheck} advanceFilterData={finalFilteredData} />
                          <div className="search-bar-advanced-search">{(fieldToSearch === 'investigators' || fieldToSearch === 'centers' || fieldToSearch === 'parent_centers') && <Button onClick={onAdvanceSearchClick} className={valueToCheck === true ? 'disable-advance-search' : 'advance-search'}><span className="adv-title">Advanced Search</span> <DownCircleOutlined /></Button>}</div>
                        </div>
                        {showAdvanceSearchModal && fieldToSearch && (
                        <RenderAdvanceSearchModal
                          setData={setData}
                          filterData={filterData}
                          setFinalFilteredData={setFinalFilteredData}
                          showAdvanceSearchModal={showAdvanceSearchModal}
                          setAdvanceSearchModal={setAdvanceSearchModal}
                          fieldToSearch={fieldToSearch}
                        />
                        )}
                        <div className="search-wrap-fields">
                          <div className="search-wrap-fields-title">Search in:</div>
                          <div className="search-wrap-fields-data">
                            <Radio.Group onChange={onFieldChange} value={fieldToSearch} disabled={isSponsor()}>
                              <Radio value="all">All</Radio>
                              <Radio value="investigators">Investigators</Radio>
                              <Radio value="centers">Centers</Radio>
                              <Radio value="parent_centers">Parent Institutions</Radio>
                              <Radio value="projects">Projects</Radio>
                              <Radio value="networks">Networks</Radio>
                              <Radio value="clinical_trials">Clinical Trials</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    )}
          </div>
        </div>
      ) : null}
      <Modal
        visible={isTaggedCommentModalOpen}
        className="tagged-user-modal"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsTaggedCommentModalOpen(false)}
      >
        <TaggedComments />
      </Modal>
    </div>
  ) : (
    ''
  );
};

export default Header;
