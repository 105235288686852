import { Button } from 'antd';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';
import { getCenterSurveyDeepDiveDataAction, getCenterSurveyDeepDiveMoreDataAction } from '../CenterSurvey/logic';
import './surveydeepdive.scss'

export default function SurveyDeepDives() {
  const history = useHistory();
  const dispatch = useDispatch();
  const centerSurveyDeepDiveResponse = useSelector((state) => state.centerSurveyDeepDiveResponse);
  const [surveyList, setSurveyList] = useState([]);
  const [surveyDataFrom, setsurveyDataFrom] = useState(1);

  useEffect(() => {
    dispatch(getCenterSurveyDeepDiveDataAction({
      survey_id: parse(location.search).survey_id,
      contact_id: parse(location.search).contact_id,
      response_id: parse(location.search).response_id ? parse(location.search).response_id : undefined,
      dataset: 'survey_questions_list',
      from: 1,
      size: 10,
    }));
  }, [])

  useEffect(() => {
    if (centerSurveyDeepDiveResponse.flag) {
      setSurveyList(centerSurveyDeepDiveResponse?.data?.data)
    }
  }, [JSON.stringify(centerSurveyDeepDiveResponse)])

  const handleBackButton = () => {
    history.goBack();
  };

  const renderSurveyDeepDive = () => {
    return (
      <Loader loading={centerSurveyDeepDiveResponse.loading} error={centerSurveyDeepDiveResponse.error}>

        {surveyList && surveyList?.map((itm, idx) => {
          return (
            <div className="survey-name-content-view">
              <div className="ques-wrap">
                {idx + 1 }.{itm?.question_details?.question_text}
              </div>
              <div className="resp-wrap">
                {itm?.response_value?.length ? itm.response_value : 'No answer'}
              </div>
            </div>
          )
        })}
      </Loader>
    )
  }

  const loadMoreSurveyData = () => {
    dispatch(getCenterSurveyDeepDiveMoreDataAction({
      survey_id: parse(location.search).survey_id,
      contact_id: parse(location.search).contact_id,
      response_id: parse(location.search).response_id ? parse(location.search).response_id : undefined,
      dataset: 'survey_questions_list',
      from: surveyDataFrom + 1,
      size: 10,
    }));
    setsurveyDataFrom(surveyDataFrom + 1);
  }

  return (
    <div className="header-wrapper survey-name-data">
      <div className="top-header">
        <Button
          onClick={handleBackButton}
          tabIndex={0}
          role="button"
          onKeyDown={null}
          className="header-back-btn"
        >
          Back
        </Button>
      </div>
      <div className="survey-name-data-content">
        <div className="survey-name-data-content-card card">
          <div className="header-wrap-survey-link">
            <div className="survey-header"> {parse(location.search).sentOn} </div>
            <div className="survey-header survey-header-link"> {parse(location.search).surveyName} </div>
            <div className="survey-header"> {parse(location.search).sentTo} </div>
            <div className="survey-header"> {parse(location.search).respondedOn} </div>
          </div>
          {
            centerSurveyDeepDiveResponse.flag && surveyList?.length
              ? (
                <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
                  <div className="show-doc-data">
                    Showing<span className="show-count">{surveyList?.length}</span> out of
                    <span className="show-count-number">{centerSurveyDeepDiveResponse?.data?.total}</span> records
                  </div>
                </div>
              )
              : null
            }
          <div>
            <LazyLoadDiv
              className="card-list scrollbar"
              id="analysis-list"
              total={centerSurveyDeepDiveResponse.data.total}
              currentTotal={(surveyList || []).length}
              loadMoreRows={() => loadMoreSurveyData()}
              height="calc(100vh - 275px)"
            >
              {renderSurveyDeepDive()}
            </LazyLoadDiv>
          </div>
        </div>
      </div>
    </div>
  )
}
