import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const LoaderComponent = () => (
  <div className="list-loader relative">
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  </div>
)

const LazyLoadDiv = ({
  currentTotal, children, id, height, className, loader, total, loadMoreRows,
}) => {
  const [loading, showLoader] = useState(false)

  const onScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.getElementById(id)
    if (scrollHeight - scrollTop - 50 <= clientHeight && total > currentTotal) {
      if (!loading) {
        loadMoreRows()
      }
      showLoader(true)
    }
  }

  useEffect(() => {
    showLoader(false)
    return () => {
      window.removeEventListener('scroll', onScroll)
    };
  }, [currentTotal])

  const style = {
    height, minHeight: height, overflowY: 'auto', position: 'relative',
  }

  if (id) {
    return (
      <div className={`${className} scrollbar`} id={id} onScroll={onScroll} style={style}>
        {children}
        {loading && loader}
      </div>
    )
  }
  return null
}

LazyLoadDiv.propTypes = {
  currentTotal: PropTypes.number,
  total: PropTypes.number,
  loadMoreRows: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loader: PropTypes.node,
  className: PropTypes.string,
}

LazyLoadDiv.defaultProps = {
  currentTotal: 0,
  total: 0,
  height: 300,
  loader: <LoaderComponent />,
  className: '',
}

export default LazyLoadDiv
