import React from 'react'

const Error404 = () => (
  <div className="error-page center">
    <div>
      <div className="error-img error-404" />
      <div className="error-details">
        <div className="error-title">
          404
        </div>
        <div className="error-sub-title">
          <h3>Page not found</h3>
        </div>
        <div className="error-desc">
          The page you are looking for does not exist. Check the URL or go to the homepage.
        </div>
        <div className="go-home-button">
          <a href={process.env.mainRoute} className="error-btn">
            Go To Home
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Error404
