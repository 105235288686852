import React, { useState, useEffect, useRef } from 'react';
import { Modal, Tag } from 'antd';
import {
  MapContainer, Popup, TileLayer, CircleMarker, Tooltip,
} from '@monsonjeremy/react-leaflet';
import StarRatings from 'react-star-ratings';
import { stringify } from 'query-string';
import { getFormattedDate } from '../../../utils';

import './map.scss';

const MyMarker = (props) => {
  const leafletRef = useRef();

  useEffect(() => {
    leafletRef.current.openPopup();
  }, []);

  return <CircleMarker ref={leafletRef} {...props} />;
};

export default function RunAnalysisMap(myProps) {
  const { data } = myProps;
  let centerPosition = data && data.length && data[0].position ? data[0].position : [];
  const [mapData, setMapData] = useState(myProps.data);
  const [displayComplianceConfirm, setDisplayComplianceConfirm] = useState(false);

  useEffect(() => {
    setMapData(myProps.data);
    centerPosition = myProps.data[0]?.position;
  }, [myProps.data])

  const popUpData = (item) => {
    return (
      <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false}>
        <div
          className="popUp-first"
          tabIndex={0}
          role="button"
          onKeyDown={null}
        >
          <div className="popUp-second">
            <div className="pimap-main-anlysis-name-wrapper">{item.site_name}</div>
            <div className="popUp-fifth map-popup-pis-list-wrap">
              <div className="map-popup-pis-list map-result-list-card">
                {item.pis.map((pi_itm, index) => (
                  <div className="map-popup-pi-card-wrap map-popup-pi-card-wrap-anl" key={index}>
                    <a
                      className="map-result-list-card-title map-result-list-card-title-link"
                      title={pi_itm.pi_name}
                      target="_blank"
                      href={`/search/investigatorprofile?${stringify({
                        query: pi_itm.pi_name,
                        id: pi_itm.pi_id,
                        type: 'PI',
                      })}`}
                      rel="noreferrer noopener"
                    >
                      {pi_itm.pi_name}
                    </a>
                    <div className="map-result-list-card-content">
                      <div className="map-location">{pi_itm.primary_organization ? pi_itm.primary_organization.name : '-'}</div>
                      <div className="map-location">{pi_itm.primary_organization ? `${pi_itm.primary_organization.city}, ${pi_itm.primary_organization.state}, ${pi_itm.primary_organization.country}, ${pi_itm.primary_organization.zip}` : '-'}</div>
                    </div>
                    <div className="map-result-list-card-rating">
                      <div className="rating">
                        <div className="reliability rating-sec">
                          <div className="rating-sec-title">Reliability</div>
                          {pi_itm.reliability_score !== ''
                            ? (
                              <StarRatings
                                rating={parseInt(pi_itm.reliability_score, 10)}
                                starRatedColor="#8A0050"
                                numberOfStars={3}
                                starDimension="16px"
                                starSpacing="1px"
                                starEmptyColor="#D099B9"
                              />
                            )
                            : (
                              <div className="dash-nodata">-</div>
                            )}
                        </div>
                        <div className="reliability rating-sec">
                          <div className="rating-sec-title">Quality:</div>
                          {pi_itm.quality_rating !== ''
                            ? (
                              <StarRatings
                                rating={pi_itm.quality_rating}
                                starRatedColor="#8A0050"
                                numberOfStars={3}
                                starDimension="16px"
                                starSpacing="1px"
                                starEmptyColor="#D099B9"
                                // name='rating'
                              />
                            )
                            : (
                              <div className="dash-nodata">-</div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="map-result-list-card-content">
                      <div className="content-title">Specialities:</div>
                      {pi_itm.specialties && pi_itm.specialties.length
                        ? (
                          <div>{pi_itm.specialties.join('; ')}</div>
                        )
                        : (
                          <div className="dash-nodata">-</div>
                        )}
                    </div>
                    <div className="map-result-list-card-content">
                      <div className="content-title">Last Trial Start Date:</div>
                      {pi_itm.recent_trial_start_date_ts
                        ? (
                          <div>{getFormattedDate(pi_itm.recent_trial_start_date_ts * 1000)}</div>
                        )
                        : (
                          <div className="dash-nodata">-</div>
                        )}
                    </div>
                    <div className="map-result-list-card-content">
                      <div className="map-tag">
                        <Tag>
                          <div className="details-abt-tags">
                            <div className="nameOfTag"># Industry Studies</div>
                            <div className="sizeOftag">{pi_itm.industry_studies}</div>
                          </div>
                        </Tag>
                        {pi_itm.tier !== ''
                          ? (
                            <Tag>
                              <div className="details-abt-tags">
                                <div className="nameOfTag">Tier</div>
                                <div className="sizeOftag">{pi_itm.tier}</div>
                              </div>
                            </Tag>
                          )
                          : null}
                        <Tag>
                          <div className="details-abt-tags">
                            <div className="nameOfTag" title="KOL scores are derived based on research/information authored by the investigator like publications, clinical trials, congress articles, patents, grants, guidelines etc. Each document is scored considering several factors like type of document, recency of the document, role of the investigator in a given document, source where the document is published etc. Scores for each individual document is aggregated and weighted by data class, to calculate the raw KOL score. The raw KOL score for the investigators is then numerically scaled between 0 to 100 considering rest of the investigators in LandSCAPE.">KOL Score</div>
                            <div className="sizeOftag">{pi_itm.cls_kol_score}</div>
                          </div>
                        </Tag>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  return (
    <>
      <div id="map">
        {data && data.length
          ? (
            <MapContainer center={centerPosition} zoom={0} style={{ height: '100%', width: '100%' }}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                minZoom={2}
                maxZoom={19}
              />
              {mapData.length
                ? mapData.map((item, ind) => {
                  return item.active === true ? (
                    <MyMarker
                      center={item.position}
                      className="circle-marker-map"
                      fillOpacity={0.5}
                      radius={8}
                      key={ind}
                    >
                      {popUpData(item)}
                    </MyMarker>
                  ) : (
                    <CircleMarker
                      center={item.position}
                      fillOpacity={0.5}
                      className="circle-marker-map"
                      // pathOptions={{ color: 'yellow' }}
                      radius={8}
                      key={ind}
                    >
                      {popUpData(item)}
                      <Tooltip
                        direction="top"
                        // offset={[0, 20]}
                        // opacity={1}
                        permanent
                        className="run-analysis-map-tooltip"
                      >
                        {item.pis.length}
                      </Tooltip>
                    </CircleMarker>
                  );
                })
                : null}
            </MapContainer>
          )
          : (
            <div className="no-data-found-msg">
              No relevant data found
            </div>
          )}
      </div>
      {displayComplianceConfirm
        ? (
          <Modal
            title="Compliance issue"
            visible={displayComplianceConfirm}
            okText="Yes"
            className="del-modal-wrapper conf-modal-wrapper"
            onCancel={() => {
              setDisplayComplianceConfirm(false);
            }}
            onOk={() => {
              setDisplayComplianceConfirm(false);
            }}
            okType="primary"
            destroyOnClose
          >
            <div className="modal-content">
              <div className="conf-desc">This investigator has a compliance issue preventing study participation.</div>
              <div className="process-text">Do you still want to add them to the shortlist?</div>
            </div>
          </Modal>
        )
        : null}
    </>
  );
}
