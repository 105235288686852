import React, { useState, useEffect } from 'react'

const TagsBar = (props) => {
  const tagsData = props.tags;
  const [currentTab, setCurrentTab] = useState(props.activeTab);
  const [activeToggle, setActiveToggle] = useState('primary');

  useEffect(() => {
    setCurrentTab(props.activeTab);
  }, [props.activeTab]);

  return (
    <div className="details-page-tabs toggle-wt-tabs">
      <div className="searbar-tabs">
        {tagsData.map((tabItm, index) => (
          <div
            className={`tagsbutton ${currentTab === tabItm.key ? 'active' : ''} ${tabItm.additionalClass}`}
            role="presentation"
            onClick={() => {
              if (currentTab !== tabItm.key && tabItm.additionalClass.indexOf('disabled') === -1) {
                setCurrentTab(tabItm.key);
                props.onTabChange(tabItm);
              }
            }}
            title={tabItm.title}
            key={index}
          >
            <div className="tab-title">
              {tabItm.name}
            </div>
          </div>
        ))}
      </div>
      {props.showInvestigatorToggle
        ? (
          <div className="toggle-switch-wrap">
            <div
              className={`toggle-switch-itm ${activeToggle === 'primary' ? 'active' : ''}`}
              role="presentation"
              onClick={() => {
                setActiveToggle('primary');
                props.onToggleChange('primary');
              }}
            >
              Primary
            </div>
            <div
              className={`toggle-switch-itm ${activeToggle === 'other' ? 'active' : ''}`}
              role="presentation"
              onClick={() => { setActiveToggle('other'); props.onToggleChange('other'); }}
            >
              Others
            </div>
          </div>
        )
        : null}
    </div>
  )
}

TagsBar.propTypes = {}

export default TagsBar
