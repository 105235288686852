import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation, Route } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { stringify } from 'query-string';
import { isSponsor } from '../../../utils';

const AuthRoute = ({ altRoute, ...rest }) => {
  const location = useLocation()
  const sponsorAllowedPaths = [
    '/savedproject',
    '/sharedprojects',
    '/projects/projectdetails',
    '/projects/savedproject',
    '/search/investigatorprofile',
    '/search/deepdives',
  ]
  const session = useSelector((state) => state.session)
  if (session) {
    if (isSponsor()) {
      if (sponsorAllowedPaths.includes(rest.location.pathname)) {
        console.log('rest-->', rest.location.pathname)
        return <Route {...rest} />
      } return <Redirect to="/sharedprojects" />
    } return <Route {...rest} />
  }
  return <Redirect to={{ pathname: (altRoute || process.env.mainRoute), search: stringify({ redirect_url: `${location.pathname}${location.search}${location.hash}` }) }} />
}

AuthRoute.propTypes = {
  altRoute: PropTypes.string,
}

AuthRoute.defaultProps = {
  altRoute: '',
}

export default AuthRoute
