import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from '../common/container/AuthRoute';
import UnAuthRoute from '../common/container/UnAuthRoute';
import Header from '../common/container/Header';
import Loader from '../common/components/Loader';
import Error404 from '../common/components/Error404';
import DeepDives from './container/DeepDiveScreen';
import SurveyDeepDives from './container/SurveyDeepDives'
import RunQuery from './container/RunQuery';
import RunAnalysis from './container/RunAnalysis';
import CompareInvestigators from './container/CompareInvestigators';
import SavedAnalysisResults from './container/SavedAnalysisResults';
import SavedProjectResults from './container/SavedProjectResults';
import CompareAnalysis from './container/CompareAnalysis';
import NetworkDetails from './container/NetworkDetails';
import CompareSites from './container/CompareSites';
import CTDetails from './container/CTDetails';
import CTDetailsResultDeepDive from './components/CTDetailsResultDeepDive';
import CTDetailsMentionSourcesDeepDive from './components/CTDetailsMentionSourcesDeepDive';
import InviteUser from './container/InviteUser';

const SearchResultsPage = lazy(() => import('./container/SearchResults'));
const SearchPage = lazy(() => import('./container/Search'));
const LoginPage = lazy(() => import('../common/container/Login'));
const UserPolicyPage = lazy(() => import('../common/container/UserPolicy'));
const PrivacyPolicyPage = lazy(() => import('../common/container/PrivacyPolicy'));
const LandingPage = lazy(() => import('../common/container/Landing'));
const InvestigatorProfile = lazy(() => import('./components/InvestigatorProfilePage'));
const CountryProfile = lazy(() => import('./components/CountryProfilePage'));
const MyProjects = lazy(() => import('./container/MyProjects'));
const SharedWithMe = lazy(() => import('./container/SharedWithMe'));
const CreateProject = lazy(() => import('./components/CreateProject'));
const ProjectDetails = lazy(() => import('./components/ProjectDetails'));
const AddCenter = lazy(() => import('./container/AddCenter'));
const MyWorkspace = lazy(() => import('./container/MyWorkspace'));
const Countries = lazy(() => import('./components/Countries'));

const Main = () => (
  <Suspense fallback={<Loader height="100vh" loading error={false} />}>
    <Header />
    <Switch>
      {process.env.isAuthModal ? (
        <UnAuthRoute exact path="/" component={LandingPage} />
      ) : (
        <UnAuthRoute exact path="/" component={LoginPage} />
      )}
      {/* <UnAuthRoute exact path="/" component={LoginPage} /> */}
      {
        // <UnAuthRoute exact path="/login" component={LoginPage} />
        // <UnAuthRoute exact path="/register" component={RegisterPage} />
      }
      <Route exact path="/inviteuser" component={InviteUser} />
      <AuthRoute exact path="/search" component={SearchPage} />
      <AuthRoute exact path="/searchresults" component={SearchResultsPage} />
      <AuthRoute exact path="/search/deepdives" component={DeepDives} />
      <AuthRoute exact path="/search/investigatorprofile" component={InvestigatorProfile} />
      <AuthRoute exact path="/search/countryprofile" component={CountryProfile} />
      <AuthRoute exact path="/search/networkdetails" component={NetworkDetails} />
      <AuthRoute exact path="/search/ctdetails" component={CTDetails} />
      <AuthRoute exact path="/search/ctdetails/resultdeepdive" component={CTDetailsResultDeepDive} />
      <AuthRoute exact path="/search/ctdetails/mentionsources" component={CTDetailsMentionSourcesDeepDive} />
      <AuthRoute exact path="/projects/createproject" component={CreateProject} />
      <AuthRoute exact path="/projects/projectdetails" component={ProjectDetails} />
      <AuthRoute exact path="/projects" component={MyProjects} />
      <AuthRoute exact path="/sharedprojects" component={SharedWithMe} />
      {/* <AuthRoute exact path="/workspace/investigators" component={InvestigatorList} /> */}
      <AuthRoute exact path="/centers/addcenter" component={AddCenter} />
      <AuthRoute exact path="/projects/runquery" component={RunQuery} />
      <AuthRoute exact path="/workspace" component={MyWorkspace} />
      <AuthRoute exact path="/countries" component={Countries} />
      <AuthRoute exact path="/projects/runanalysis" component={RunAnalysis} />
      <AuthRoute exact path="/projects/compareinvestigators" component={CompareInvestigators} />
      <AuthRoute exact path="/projects/compareanalysis" component={CompareAnalysis} />
      <AuthRoute exact path="/projects/savedanalysis" component={SavedAnalysisResults} />
      <AuthRoute exact path="/projects/savedproject" component={SavedProjectResults} />
      <AuthRoute exact path="/projects/comparesites" component={CompareSites} />
      <AuthRoute exact path="/search/deepdives/surveydeepdive" component={SurveyDeepDives} />

      {
        // <AuthRoute exact path="/search/map" component={Map} />
        // <Route exact path="/csspage" component={CssPage} />
      }

      <Route exact path="/userpolicy" component={UserPolicyPage} />
      <Route exact path="/privacypolicy" component={PrivacyPolicyPage} />
      <Route component={Error404} />
    </Switch>
  </Suspense>
);

export default Main;
