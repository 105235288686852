import { combineReducers } from 'redux';
import commonReducers from '../common/reducers';
import { getAutocompleteValuesReducer } from './container/SearchBar/logic';
import {
  getProjectCountsReducer, getNotificationsReducer, updateNotificationReducer, getTaggedCommentsReducer, getAccessPermissionsReducer,
} from './container/Search/logic';
import {
  getCountsDataReducer,
  getDatasetListDataReducer,
  getSearchFieldsFilterReducer,
  getSearchResultFilterReducer,
  getMapCoordinatesReducer,
  getMapListDataReducer,
} from './container/SearchResults/logic';

import {
  getDeepdiveValuesReducer, tabsDeepdiveListReducer, getSitePiDetailsFromTrialReducer, getPurpleLabDataForSiteExperienceReducer,
} from './container/DeepDiveScreen/logic';
import { deleteProjectsDataReducer, getProjectsDataReducer } from './container/MyProjects/logic';
import {
  getProjectAnalysisReducer,
  createProjectAnalysisReducer,
  createProjectQueryReducer,
  getQueriesReducer,
  updateProjectAnalysisReducer,
  updateProjectQueryReducer,
  deleteProjectAnalysisReducer,
  deleteQueriesReducer,
  markInvalidAnalysisReducer,
  markInvalidProjectReducer,
  queryDragNDropReducer,
  markFinalAnalysisReducer,
  runProjectReducer,
  analysisDragNDropReducer,
} from './components/ProjectDetails/logic';

import { copyAnalysisReducer } from './container/CopyProjectAnalysis/logic';
import { getCopyQueryReducer, copyProjectAnalysisReducer } from './container/CopyProjectQueries/logic';
import {
  getRegionValueReducer, getautosuggestvalueReducer, getotherautosuggestvalueReducer, getautosuggestvalueForSubregionReducer,
} from './container/AddProjectName/logic';
import {
  getRunAnalysisReducer,
  getRunAnalysisCountsReducer,
  saveRunAnalysisCountsReducer,
} from './container/RunQuery/logic';
import { copyProjectReducer } from './container/CopyProjectModal/logic';
import { createBookmarkReducer, deleteBookmarkReducer } from '../common/components/Bookmark/logic';
import { getSavedAnalysisReducer, getSavedAnalysisCountsReducer } from './container/SavedAnalysisResults/logic';
import {
  ListUsersReducer,
  shareProjectReducer,
  getSharedProjectUsersDataReducer,
  changeShareProjectAccessReducer,
  getSponsorListReducer,
  getSponsorUserListReducer,
  createSponsorReducer,
  createSponsorUserReducer,
} from './container/ShareProjectModal/logic';
import {
  shareAnalysisReducer,
  changeShareAnalysisAccessReducer,
  getSharedAnalysisUsersDataReducer,
} from './container/ShareAnalysisModal/logic';
import {
  getCommentsReducer,
  createCommentReducer,
  resolveCommentReducer,
  deleteCommentReducer,
  updateCommentReducer,
  getCommentsCountsReducer,
  getCommentsForApprovalReducer,
  createCommentForApprovalReducer,
  deleteCommentForApprovalReducer,
  updateCommentForApprovalReducer,
} from './container/Comment/logic';
import { getBookmarkDataReducer, getBookmarkCountsDataReducer } from './container/InvestigatorList/logic';
import {
  shortlistPiReducer,
  deleteshortlistedPiReducer,
  getAnalysisFilterReducer,
} from './container/RunAnalysis/logic';
import { getSharedProjectsDataReducer, reviewAnalysisReducer, reviewProjectReducer } from './container/SharedWithMe/logic';
import { addPiReducer } from './container/DocumentList/logic';
import {
  createPiReducer, getParentDetailsReducer, getPiFilterDataReducer, simsIdPresentReducer, updatePiReducer, getParentDetailsForCenterReducer,
} from './components/AddInvestigator/logic';
import { createSiteReducer, getSiteQuesDataReducer, updateSiteReducer } from './components/AddCentersManually/logic';
import { getSearchHistoryReducer, createSearchHistoryReducer } from './container/SearchHistory/logic';
import {
  getStudiesDataReducer, getStudiesGraphReducer, getStudiesFiltersReducer, getOtherStudiesDataReducer, getParentCenterStudiesDataReducer, getParentStudiesFiltersReducer,
} from './container/InvestigatorStudies/logic';
import { getPiQuesDataReducer } from './container/ResearchInterest/logic';
import { addNetworkReducer, addPiToNetworkReducer, getNetworkAutoSuggestReducer } from './container/Network/logic';
import {
  deletePiFromNetworkReducer,
  getNetworkDetailsReducer,
  getPiAutoSuggestReducer,
  modifyNetworkReducer,
  putPiToNetworkReducer,
  deleteNetworkReducer,
} from './container/NetworkDetails/logic';
import { getPaymentDataReducer } from './container/DashboardPayments/logic';
import { getCensusDataReducer, getPurpleLabDataForPIForExperienceReducer, getPurpleLabDataForSiteSDOHReducer } from './container/DashboardData/logic';
import {
  uploadSponsorsFileReducer,
  addColumnMappingReducer,
  getUploadedPIsMetaReducer,
  getUploadedPIsListReducer,
  updateUploadedPIReducer,
  addUploadedPIsReducer,
} from './container/UploadSponsorsList/logic';
import { capacityGraphDataReducer } from './container/Capacity/logic';
import {
  getCenterSurveyDataReducer, getCenterSurveyDeepDiveDataReducer, getCenterSurveySummaryDataReducer, getInvestigatorSurveyDataReducer, getInvestigatorSurveySummaryDataReducer, getParentCenterSurveyDataReducer,
} from './container/CenterSurvey/logic';
import { getStateCancerFilterReducer, getStateCancerReducer } from './container/StateCancerProfile/logic';
import {
  getHierarchyReducer,
  getPisInHierarchyReducer,
  addChildCenterInHierarchyReducer,
  removeChildCenterFromHierarchyReducer,
  changeParentInHierarchyReducer,
  getParentInstitutionHierarchyReducer,
} from './container/InvestigatorHierarchy/logic';
import {
  deleteCountryProfileDataReducer,
  getCountryProfileDataReducer, getCountryProfileFiltersReducer, getWhoLifeExpReducer, updateCountryProfileDataReducer,
} from './container/CountryBackground/logic';
import {
  getCancerDataReducer, getCovidCountriesReducer, getCovidDataReducer, getGbdDataReducer, getGbdFilterReducer,
} from './container/Epidemiology/logic';
import { deletePIReducer, markSiteValidInvalidReducer, markValidInvalidReducer } from './components/InvestigatorProfilePage/logic';
import { getStartUpReducer, getStartUpSiteReducer } from './container/StartUp/logic';
import {
  getKmrReducer, getPerformanceReducer, getPerformanceStudiesReducer, getRpFiltersReducer,
} from './container/RecruitmentPerformance/logic';
import { getCenterDataDeepDiveReducer, getCenterDataReducer } from './container/CenterAnalysis/logic';
import {
  getCenterTypeDataReducer, getChildCenterDataReducer, getCountryDataReducer, getStateDataReducer,
} from './container/GeneralInformation/logic';
import { fetchCountryReducer } from './components/Countries/logic';
import { getDeletedListOfRequestsDataReducer, getEditedListOfRequestsDataReducer, getNewListOfRequestsDataReducer } from './container/RenderNewInvestigator/logic';
import {
  adminApprovalEditPiReducer, adminApprovalPiReducer,
  userCorrectionRequestReducer, userCorrectionSiteRequestReducer,
} from './components/AddInvestigatorAdmin/logic';
import { adminApprovalSiteReducer, adminApprovalEditSiteReducer } from './components/AddCentersAdmin/logic';
import {
  getMergeDocReducer, mergePreRequestReducer, mergeRequestReducer, getCardDataReducer, approvalMergeReducer, approvalDeleteReducer,
} from './container/MergeViewModal/logic';

import { deleteRequestedSiteReducer } from './container/RenderNewSite/logic';
import { mergeRequestsListReducer } from './components/AdminMergedSiteRequests/logic';
import { addRiskReducer, deleteRiskReducer, updateRiskReducer } from './container/AdminCountryRisks/logic';
import {
  reportEditedReducer, reportMergedReducer, reportNewlyAddedDownloadReducer, reportNewlyAddedReducer,
} from './container/AdminReports/logic';
import { getMentionOtherSourcesDataReducer } from './container/CTDeepDiveMentionInOtherSources/logic';
import { getHistoryChangeDataReducer } from './container/CTDeepDiveHistoryOfChanges/logic';
import {
  addGroupMembersReducer, createGroupReducer, deleteGroupReducer, getGroupMembersReducer, getGroupsReducer, deleteGroupMemberReducer, updateGroupReducer,
} from './components/UserGroups/logic';
import {
  changePreferredSiteReducer,
  createNewVersionReducer,
  getPreferredSiteReducer, getSavedProjectCountsReducer, getSavedProjectReducer, updatePiStatusReducer,
  getQuestionReducer, addQuestionsReducer, updateQuestionsReducer, deleteQuestionsReducer, addResponseReducer,
  getQuestionAdditionalDetailsReducer, updateResponseReducer, deleteResponseReducer,
} from './container/SavedProjectResults/logic';
import { getProjectSummaryDataReducer } from './components/ProjectSummaryView/logic';
import { getProjectFilterReducer, getProjectFilterForCountryReducer } from './components/ProjectFilters/logic';
import { getReferenceDataReducer } from './components/ReferenceDataTable/logic';
import { instituteDeepdiveListReducer } from './container/InstitutionDetails/logic';
import { instituteNetworkReducer } from './container/InstitutionNetworks/logic';
import { institutionConstitutentCentersListReducer } from './container/InstituteConstitutentCenters/logic';
import { addParentReducer, updateParentReducer } from './components/AddCenterManuallyAddNew/logic';
import { getParentGraphCenterDataReducer } from '../common/container/ShowCentersData/logic';
import { getCompliancePiFilterReducer } from './container/PIComplianceIssues/logic';
import { deleteNotificationReducer } from '../common/container/Header/logic';
import { getInstituteStudiesDataReducer, getParentStudiesGraphReducer } from './container/InstituteTrialsAtSite/logic';
import { instituteMetricsDeepdiveListReducer } from './container/InstitutionMetrics/logic';
import {
  getShortListedBgMapDataReducer, getShortListedMapMarkerDataReducer, getProjectPiListReducer, getProjectPiListToRenderReducer,
  getSnippetViewReducer,
  addToSnippetViewReducer,
  deletePiFromSnippetViewReducer,
} from './container/MapVIewSavedProjectResults/ShortlistedVsCandidatesMap/logic';

import { getRoleForInviteReducer, inviteUserReducer } from './container/AdminDashboard/logic';
import { createCountryCriteriaReducer, createCountrySummaryReducer, getCountryCriteriaReducer } from './components/CreateCountrySummary/logic';
import { getCountrySummaryReducer, updateCountrySummaryReducer } from './components/ViewCountrySummaryComp/logic';
import { getMedidataSiteOrPIReducer } from './container/MedidataMetrics/logic';
import { getMedidataForCountryReducer, getMedidataFilterForCountryReducer } from './container/MediDataMetricesForCountryProfile/logic';
import { getPurpleLabDataForPIReducer } from './container/PurpleLabDataForInvestigator/logic';
import { getPurpleLabDataForSiteEffectivenessReducer } from './container/PurpleLabDataForCenter/logic';
import { getSearchResultFilterForTabReducer } from './container/SearchResults/searchResultFilterForTabLogic';
import { addPiForCTReducer } from './container/CTDeepDiveInvestigatorDetails/logic';
import { uploadBulkUpdateFileReducer } from './components/BulkUpdate/logic'

const rootReducer = combineReducers({
  ...commonReducers,
  projectCountsData: getProjectCountsReducer,
  autoCompleteData: getAutocompleteValuesReducer,
  countsData: getCountsDataReducer,
  datasetListData: getDatasetListDataReducer,
  deepDiveData: getDeepdiveValuesReducer,
  projectsData: getProjectsDataReducer,
  projectAnalysisData: getProjectAnalysisReducer,
  createProjectAnalysisResponse: createProjectAnalysisReducer,
  createProjectQueryResponse: createProjectQueryReducer,
  queriesData: getQueriesReducer,
  updateProjectAnalysisResponse: updateProjectAnalysisReducer,
  updateProjectQueryResponse: updateProjectQueryReducer,
  copyProjectAnalysisData: copyProjectAnalysisReducer,
  deleteProjectAnalysisResponse: deleteProjectAnalysisReducer,
  deleteQueriesResponse: deleteQueriesReducer,
  copyAnalysisResponse: copyAnalysisReducer,
  copyQueryResponse: getCopyQueryReducer,
  autosuggestdata: getautosuggestvalueReducer,
  autosuggestotherdata: getotherautosuggestvalueReducer,
  markProjectInvalidResponse: markInvalidProjectReducer,
  markAnalysisInvalidResponse: markInvalidAnalysisReducer,
  runAnalysisData: getRunAnalysisReducer,
  copyProjectData: copyProjectReducer,
  createBookmark: createBookmarkReducer,
  deleteBookmark: deleteBookmarkReducer,
  savedAnalysisResultsData: getSavedAnalysisReducer,
  savedAnalysisResultsCountsData: getSavedAnalysisCountsReducer,
  savedProjectResultsData: getSavedProjectReducer,
  savedProjectResultsCountsData: getSavedProjectCountsReducer,
  compareInvestigatorListData: getDatasetListDataReducer,
  usersList: ListUsersReducer,
  dragNdropResp: queryDragNDropReducer,
  shareProjectResp: shareProjectReducer,
  commentsData: getCommentsReducer,
  runAnalysisCountsData: getRunAnalysisCountsReducer,
  createCommentResponse: createCommentReducer,
  bookmarkData: getBookmarkDataReducer,
  resolveCommentResponse: resolveCommentReducer,
  deleteCommentResponse: deleteCommentReducer,
  bookmarkCount: getBookmarkCountsDataReducer,
  updateCommentResponse: updateCommentReducer,
  commentsCountsData: getCommentsCountsReducer,
  shortlistPiResponse: shortlistPiReducer,
  deleteShortlistedPiResponse: deleteshortlistedPiReducer,
  sharedProjectUsersData: getSharedProjectUsersDataReducer,
  sharedProjects: getSharedProjectsDataReducer,
  changeProjectAccessResponse: changeShareProjectAccessReducer,
  shareAnalysisResp: shareAnalysisReducer,
  sharedAnalysisUsersData: getSharedAnalysisUsersDataReducer,
  changeAnalysisAccessResponse: changeShareAnalysisAccessReducer,
  addPiResponse: addPiReducer,
  saveAnalysisCountsResponse: saveRunAnalysisCountsReducer,
  searchHistoryData: getSearchHistoryReducer,
  createPiResponse: createPiReducer,
  createSiteResponse: createSiteReducer,
  updateSiteResponse: updateSiteReducer,
  updatePiResponse: updatePiReducer,
  studiesData: getStudiesDataReducer,
  studiesGraphData: getStudiesGraphReducer,
  getParentStudiesGraphData: getParentStudiesGraphReducer,
  studiesFiltersData: getStudiesFiltersReducer,
  runAnalysisFilters: getAnalysisFilterReducer,
  notificationsData: getNotificationsReducer,
  taggedCommentsData: getTaggedCommentsReducer,
  updateNotificationResp: updateNotificationReducer,
  createSearchHistoryResp: createSearchHistoryReducer,
  piQuesResponse: getPiQuesDataReducer,
  siteQuesResponse: getSiteQuesDataReducer,
  addNetwork: addNetworkReducer,
  getNetworkDetails: getNetworkDetailsReducer,
  deletePiFromNetwork: deletePiFromNetworkReducer,
  getPiAutoSuggest: getPiAutoSuggestReducer,
  putPiToNetwork: putPiToNetworkReducer,
  networkAutoSuggest: getNetworkAutoSuggestReducer,
  modifyNetwork: modifyNetworkReducer,
  deleteNetwork: deleteNetworkReducer,
  paymentDataResponse: getPaymentDataReducer,
  censusDataResponse: getCensusDataReducer,
  uploadSponsorsFileResponse: uploadSponsorsFileReducer,
  addColumnMappingResponse: addColumnMappingReducer,
  getUploadedPIsMeta: getUploadedPIsMetaReducer,
  getUploadedPIsList: getUploadedPIsListReducer,
  updateUploadedPIResponse: updateUploadedPIReducer,
  addUploadedPIsResponse: addUploadedPIsReducer,
  getCapacityGraphData: capacityGraphDataReducer,
  centerSurveyResponse: getCenterSurveyDataReducer,
  investigatorSurveyResponse: getInvestigatorSurveyDataReducer,
  stateCancerFilterResponse: getStateCancerFilterReducer,
  stateCancerResponse: getStateCancerReducer,
  centerSurveyDeepDiveResponse: getCenterSurveyDeepDiveDataReducer,
  getWhoLifeExpData: getWhoLifeExpReducer,
  countryProfileFilters: getCountryProfileFiltersReducer,
  countryProfileData: getCountryProfileDataReducer,
  cancerData: getCancerDataReducer,
  CovidStatistics: getCovidDataReducer,
  updateCountryProfileData: updateCountryProfileDataReducer,
  deleteCountryData: deleteCountryProfileDataReducer,
  hierarchyData: getHierarchyReducer,
  parentInstitutionHierarchyData: getParentInstitutionHierarchyReducer,
  markInvalidResponse: markValidInvalidReducer,
  markSiteValidInvalidResponse: markSiteValidInvalidReducer,
  searchResultFilterData: getSearchResultFilterReducer,
  searchFieldsFilterData: getSearchFieldsFilterReducer,
  getPiFilterDataResponse: getPiFilterDataReducer,
  startUpData: getStartUpReducer,
  performanceData: getPerformanceReducer,
  kmrData: getKmrReducer,
  centerData: getCenterDataReducer,
  getCountryData: getCountryDataReducer,
  getStateData: getStateDataReducer,
  addPiToNewtorkData: addPiToNetworkReducer,
  mapCoordinates: getMapCoordinatesReducer,
  mapListData: getMapListDataReducer,
  reviewAnalysis: reviewAnalysisReducer,
  reviewProject: reviewProjectReducer,
  countryNameFilter: getCovidCountriesReducer,
  markAnalysisFinalResponse: markFinalAnalysisReducer,
  fetchCountryResponse: fetchCountryReducer,
  gbdFilterData: getGbdFilterReducer,
  gbdData: getGbdDataReducer,
  centerTypeDataResponse: getCenterTypeDataReducer,
  getNewListOfRequestsData: getNewListOfRequestsDataReducer,
  adminApprovalPiResponse: adminApprovalPiReducer,
  adminApprovalSiteResponse: adminApprovalSiteReducer,
  getEditedListOfRequestsData: getEditedListOfRequestsDataReducer,
  getDeletedListOfRequestsData: getDeletedListOfRequestsDataReducer,
  adminApprovalEditPiResponse: adminApprovalEditPiReducer,
  mergePreRequestData: mergePreRequestReducer,
  mergeRequestFinalData: mergeRequestReducer,
  userCorrectionPIRequestResponse: userCorrectionRequestReducer,
  userCorrectionSiteRequest: userCorrectionSiteRequestReducer,
  adminApprovalEditSiteResponse: adminApprovalEditSiteReducer,
  commentsForApprovalData: getCommentsForApprovalReducer,
  createCommentForApprovalResponse: createCommentForApprovalReducer,
  deleteCommentForApprovalResponse: deleteCommentForApprovalReducer,
  updateCommentForApprovalResponse: updateCommentForApprovalReducer,
  deleteRequestedSiteResponse: deleteRequestedSiteReducer,
  mergeRequestsListData: mergeRequestsListReducer,
  getCardData: getCardDataReducer,
  getMergeDoc: getMergeDocReducer,
  approvalMerge: approvalMergeReducer,
  approvalDelete: approvalDeleteReducer,
  addRisk: addRiskReducer,
  deleteRisk: deleteRiskReducer,
  updateRisk: updateRiskReducer,
  getOtherStudiesDataResponse: getOtherStudiesDataReducer,
  tabsDeepdiveResponse: tabsDeepdiveListReducer,
  pisInHierarchyData: getPisInHierarchyReducer,
  addChildCenterInHierarchyResponse: addChildCenterInHierarchyReducer,
  removeChildCenterFromHierarchyResponse: removeChildCenterFromHierarchyReducer,
  changeParentInHierarchyResponse: changeParentInHierarchyReducer,
  reportNewlyAdded: reportNewlyAddedReducer,
  reportNewlyAddedDownload: reportNewlyAddedDownloadReducer,
  reportEdited: reportEditedReducer,
  reportMerged: reportMergedReducer,
  getMentionOtherSourcesDataResponse: getMentionOtherSourcesDataReducer,
  getHistoryChangeDataResponse: getHistoryChangeDataReducer,
  centerDataDeepDive: getCenterDataDeepDiveReducer,
  performanceStudiesData: getPerformanceStudiesReducer,
  projectSummaryData: getProjectSummaryDataReducer,
  projectFilters: getProjectFilterReducer,
  updatePiStatusResponse: updatePiStatusReducer,
  getInvestigatorSurveySummaryData: getInvestigatorSurveySummaryDataReducer,
  createGroupResponse: createGroupReducer,
  addGroupMembersResponse: addGroupMembersReducer,
  getGroupsList: getGroupsReducer,
  deleteGroupRespose: deleteGroupReducer,
  getGroupMembersList: getGroupMembersReducer,
  deleteGroupMemberRespose: deleteGroupMemberReducer,
  preferredSiteList: getPreferredSiteReducer,
  updateGroupRespose: updateGroupReducer,
  runProjectRespose: runProjectReducer,
  changePreferredSiteRespose: changePreferredSiteReducer,
  createNewVersionResponse: createNewVersionReducer,
  getRpFiltersResponse: getRpFiltersReducer,
  getStartUpSiteResponse: getStartUpSiteReducer,
  deletePIResponse: deletePIReducer,
  referenceDataResponse: getReferenceDataReducer,
  instituteDeepdiveList: instituteDeepdiveListReducer,
  instituteMetricsDeepdiveList: instituteMetricsDeepdiveListReducer,
  instituteNetworkResponse: instituteNetworkReducer,
  institutionConstitutentCentersList: institutionConstitutentCentersListReducer,
  addParentResponse: addParentReducer,
  updateParentResponse: updateParentReducer,
  deleteProjectResponse: deleteProjectsDataReducer,
  getParentCenterStudiesData: getParentCenterStudiesDataReducer,
  getParentGraphCenterDataResponse: getParentGraphCenterDataReducer,
  getCompliancePiFilterResponse: getCompliancePiFilterReducer,
  getCenterSurveySummaryDataResponse: getCenterSurveySummaryDataReducer,
  deleteNotificationResponse: deleteNotificationReducer,
  getParentCenterSurveyDataResponse: getParentCenterSurveyDataReducer,
  getInstituteStudiesDataResponse: getInstituteStudiesDataReducer,
  analysisDragDropRespose: analysisDragNDropReducer,
  regionValueData: getRegionValueReducer,
  getParentStudiesFilters: getParentStudiesFiltersReducer,
  getAccessPermissions: getAccessPermissionsReducer,
  isSimsIdPresent: simsIdPresentReducer,
  getParentDetailsResponse: getParentDetailsReducer,
  getRoleForInviteResponse: getRoleForInviteReducer,
  inviteUserResponse: inviteUserReducer,
  getChildCenterDataResponse: getChildCenterDataReducer,
  getSitePiDetailsFromTrialResponse: getSitePiDetailsFromTrialReducer,
  getShortListedMapBgDataResponse: getShortListedBgMapDataReducer,
  getShortListedMapMarkerDataResponse: getShortListedMapMarkerDataReducer,
  getProjectPiList: getProjectPiListReducer,
  getQuestionResponse: getQuestionReducer,
  addQuestionsResponse: addQuestionsReducer,
  updateQuestionsResponse: updateQuestionsReducer,
  deleteQuestionsResponse: deleteQuestionsReducer,
  addResponse: addResponseReducer,
  projectPiListToRender: getProjectPiListToRenderReducer,
  getQuestionAdditionalDetailsResponse: getQuestionAdditionalDetailsReducer,
  createCountryCriteriaResponse: createCountryCriteriaReducer,
  createCountrySummaryResponse: createCountrySummaryReducer,
  getCountryCriteriaResponse: getCountryCriteriaReducer,
  getCountrySummaryResults: getCountrySummaryReducer,
  sponsorListResponse: getSponsorListReducer,
  sponsorUserListResponse: getSponsorUserListReducer,
  createSponsorResponse: createSponsorReducer,
  createSponsorUserResponse: createSponsorUserReducer,
  getMedidataSiteOrPIResults: getMedidataSiteOrPIReducer,
  getMedidataForCountryResponse: getMedidataForCountryReducer,
  getMedidataFilterForCountryResponse: getMedidataFilterForCountryReducer,
  getPurpleLabDataForPIResponse: getPurpleLabDataForPIReducer,
  getPurpleLabDataForPIForExperienceResponse: getPurpleLabDataForPIForExperienceReducer,
  snippetViewResponse: getSnippetViewReducer,
  addToSnippetViewResponse: addToSnippetViewReducer,
  deletePiFromSnippetViewResponse: deletePiFromSnippetViewReducer,
  getPurpleLabDataForSiteSDOHResponse: getPurpleLabDataForSiteSDOHReducer,
  getPurpleLabDataForSiteEffectivenessResponse: getPurpleLabDataForSiteEffectivenessReducer,
  getPurpleLabDataForSiteExperienceResponse: getPurpleLabDataForSiteExperienceReducer,
  getSearchResultFilterForTabResponse: getSearchResultFilterForTabReducer,
  addPiForCTResponse: addPiForCTReducer,
  getParentDetailsForCenterResponse: getParentDetailsForCenterReducer,
  updateCountrySummaryResponse: updateCountrySummaryReducer,
  getProjectFilterForCountryResponse: getProjectFilterForCountryReducer,
  getautosuggestvalueForSubregionReponse: getautosuggestvalueForSubregionReducer,
  updateResponse: updateResponseReducer,
  deleteResponse: deleteResponseReducer,
  uploadBulkUpdateFileResp: uploadBulkUpdateFileReducer,
});

export default rootReducer;
