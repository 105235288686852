import React from 'react'
import { useSelector } from 'react-redux'
import auth0 from 'auth0-js'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { removeCookies } from '../../../utils'

const Logout = () => {
  const history = useHistory()
  const webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
  })
  const logout = () => {
    removeCookies()
    history.replace(process.env.mainRoute);
    webAuth.logout({
      returnTo: process.env.appUrl,
      clientID: process.env.auth0.clientId,
    })
  }
  const session = useSelector((state) => state.session)
  if (session) {
    return (
      <div
        role="presentation"
        onClick={logout}
        className="navbar-group-right-item"
      >
        Logout
      </div>
    )
  }
  return null
}

export default Logout
