import Rcookie from 'react-cookies';
import { parse, stringify } from 'query-string';
import * as d3 from 'd3';
import { toast } from 'react-toastify';
import cmp from 'semver-compare';

export const eliminateCookies = () => {
  // Get an array of cookies
  const arrSplit = document.cookie.split(';');

  for (let i = 0; i < arrSplit.length; i += 1) {
    const cookie = arrSplit[i].trim();
    const cookieName = cookie.split('=')[0];

    // If the prefix of the cookie's name matches the one specified, remove it
    if (cookieName.includes('Ontosight-main_') || cookieName.includes('Ontosight_')) {
      // Remove the cookie
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT ;domain=.ontosight.ai;path=/`;
    }
  }
}

export function generateRandomString(stringLength = 10) {
  let randomString = '';
  let randomAscii;
  const asciiLow = 65;
  const asciiHigh = 90;
  for (let i = 0; i < stringLength; i += 1) {
    randomAscii = Math.floor((10000 * (asciiHigh - asciiLow)) + asciiLow);
    randomString += String.fromCharCode(randomAscii);
  }
  return randomString;
}

export const PATHS = [
  ...Object.keys(process.env.APPS).map((item) => process.env.APPS[item].mainRoute),
];

export const setCookie = (value, key) => {
  const { domain } = process.env;
  const expires = new Date();
  expires.setDate(new Date().getDate() + 1);
  Rcookie.save(`${process.env.app}_${key}_${process.env.type}`, value, { path: '/', domain, expires });
};

export const getCookie = (key, app) => Rcookie.load(`${app || process.env.app}_${key}_${process.env.type}`);

export const removeCookies = (flag) => {
  const cookies = document.cookie.split(';');
  const { domain } = process.env;
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    PATHS.forEach((item) => {
      if (flag) {
        if (name.includes(`${process.env.app}_`)) {
          document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
        }
      } else {
        document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
      }
      return 0;
    });
  }
};

export function apiCall(url, method, body = {}, authReq = true, customHeaders = {}) {
  eliminateCookies();
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${stringify(body)}` : ''}`,
    };
  }
  const headers = {
    Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    ...customHeaders,
  }
  Object.entries(headers).forEach((en) => {
    if (en[1] === undefined || (!authReq && en[0] === 'Authorization')) {
      delete headers[en[0]]
    }
  })
  return ({
    ...obj,
    headers,
  });
}

export function apiCallwithoutCT(url, method, body = {}) {
  eliminateCookies();
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${stringify(body)}` : ''}`,
    };
  }

  return ({
    ...obj,
    headers: {
      Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    },
  });
}

export const checkPermission = (value) => {
  if (getCookie('permissions')) {
    return getCookie('permissions').indexOf(value) > -1;
  }
  return false;
};

export const j = (value) => JSON.parse(JSON.stringify(value));

export const uniqueFromArray = (value) => value.filter((item, pos) => value.indexOf(item) === pos)

export function ucFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export const parseHashUrl = (value) => parse(value.hash.replace('#', '?')) || {}

export const generateHashUrl = (value) => `#${stringify(value)}`

export const isJsonString = (str, backup = []) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return backup
  }
}

export function bookmarkStatusToggle(data = [], id = null, flag = false) {
  const DATA = JSON.parse(JSON.stringify(data))
  return DATA.map((item) => (item.id === id ? { ...item, bookmark: flag } : item))
}

export function objectValueArrayToStringForApi(data) {
  if (data && typeof data === 'object' && !Array.isArray(data)) {
    const temp = {}
    Object.keys(data).forEach((item) => {
      if (Array.isArray(data[item])) {
        if (data[item].length) {
          temp[item] = data[item].join('||')
        }
      } else {
        temp[item] = data[item]
      }
    })
    return temp
  }
  return data
}

export const style = {
  style: {
    background: 'rgba(62, 62, 62, 0.9)',
    padding: 20,
    color: '#fff',
    borderRadius: 5,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 2px',
    'z-index': '305',
    // marginTop: -60,
    transition: 'none',
  },
  arrowStyle: {
    color: 'rgba(62, 62, 62, 0.9)',
    borderColor: false,
    transition: 'none',
  },
}

export const setAnalytics = (trigger, key, data, user_id = `${getCookie('userName')}@@${getCookie('org')}`) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      event: trigger,
      ...(key ? { [key]: typeof data === 'object' ? JSON.stringify(data) : data } : {}),
      user_id,
    })
  }
}

export const wrapSvgText = (textNode, wrapLength = 25, customConfig = {}) => {
  const selection = d3.select(textNode)
  const fontSize = customConfig.fontSize || selection.attr('font-size') || selection.style('font-size') || 12
  const config = {
    maxLines: 1000,
    showTitle: true,
    fontSize,
    lineheight: 1.2 * (typeof fontSize === 'string' ? fontSize.replace('px', '') : fontSize),
    topAlign: 'middle',
    ...customConfig,
  }
  const alignMap = {
    middle: (noOfLines) => -((noOfLines + 0.5) * config.lineheight) / 2,
    top: () => 0,
    bottom: (noOfLines) => -noOfLines * config.lineheight,
  }
  selection.attr('font-size', config.fontSize)
  const words = selection.text().split(' ')
  selection.text(null)
  if (config.showTitle) {
    selection.append('title').text(words.join(' '))
  }
  words.forEach((w, i) => {
    if (w.length > wrapLength) {
      words.splice(i, 1, `${w.slice(0, wrapLength - 1)}-`, w.slice(wrapLength - 1))
    }
  })
  let count = 0
  while (words.length && count < config.maxLines) {
    let line = '';
    while (words[0] && `${line} ${words[0]}`.trim().length <= wrapLength) {
      line = `${line} ${words[0]}`.trim()
      words.splice(0, 1)
    }
    count += 1
    if (count === config.maxLines && words.length) {
      line = `${line.slice(0, line.length - 3)}...`
    }
    selection.append('tspan').attr('dy', config.lineheight).attr('x', selection.attr('x')).text(line)
  }
  selection.attr('y', alignMap[config.topAlign](selection.selectAll('tspan').size()))
}

export const blobToBase64 = (blob, callback) => {
  const reader = new FileReader();
  reader.onload = function () {
    const dataUrl = reader.result;
    const base64 = dataUrl.split(',')[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
};

export const notify = (message, type) => toast(message, {
  position: 'top-center',
  autoClose: 2000,
  pauseOnHover: false,
  draggable: true,
  newestOnTop: true,
  type,
  hideProgressBar: true,
  closeButton: false,
})

export const getFormattedDate = (date) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  if (!isNaN(new Date(date).getMonth())) {
    const dateObj = new Date(date);
    const month = dateObj.getMonth();
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}-${monthNames[month]}-${year}`;
  }
  return date;
}

export const getFormattedDateFromTimestamp = (date, flag) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]

  if (!isNaN(new Date(date * 1000).getMonth())) {
    const dateObj = new Date(date * 1000);
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    if (flag === 'MM') {
      return `${year}-${dateObj.getMonth() + 1}-${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  return date;
}

export const getFormattedTime = (date) => {
  return new Date(date).toLocaleTimeString();
}

const minBrowserVersions = {
  chrome: '64',
  Chrome: '64',
  crios: '64',
  safari: '10',
  edge: '14',
  firefox: '54',
  'edge-chromium': '79',
  ios: '9',
  iOS: '9',
}

export const checkBrowserSupport = (browser) => !!minBrowserVersions[browser.name]
  && (cmp(browser.version, minBrowserVersions[browser.name]) > 0)

export const getConcatenatedString = (arr, key) => {
  let tempString = '';
  if (arr.length > 1) {
    arr.map((arrItm) => {
      tempString += `${arrItm[key]}; `;
      return null;
    })
  } else {
    tempString = arr[0][key];
  }
  return tempString;
}

export const getYearList = () => {
  const currentYear = (new Date()).getFullYear();
  const items = [];
  for (let i = 1950; i <= currentYear; i += 1) {
    items.push({ key: i, label: i })
  }
  return items;
}

export const hasPermissionForAction = (permission) => {
  if (getCookie('access_permissions') && getCookie('access_permissions').includes(permission)) {
    return true
  }
  return false
}

export const isSponsor = () => {
  if (getCookie('permissions') && getCookie('permissions').indexOf('sponsor_access') > -1) {
    return true
  }
  return false
}
