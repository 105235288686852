import React, { useEffect, useRef } from 'react';
import { stringify } from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { Tag } from 'antd';
import {
  MapContainer, Popup, TileLayer, CircleMarker, Tooltip as Tooltip1,
} from '@monsonjeremy/react-leaflet';
import StarRatings from 'react-star-ratings';
import Loader from '../Loader';
import {
  getMapListDataAction,
} from '../../../app/container/SearchResults/logic';
import './map.scss';

const MyMarker = (props) => {
  const leafletRef = useRef();

  useEffect(() => {
    leafletRef.current.openPopup();
  }, []);

  return <CircleMarker ref={leafletRef} {...props} />;
};

export default function WorldMap(myProps) {
  const dispatch = useDispatch();
  const { data } = myProps;
  const centerPosition = data && data.length ? [data[0].lat !== '' && data[0].lat !== 'None' ? parseFloat(data[0].lat) : 0, data[0].lon !== '' && data[0].lon !== 'None' ? parseFloat(data[0].lon) : 0] : [];
  const mapListData = useSelector((store) => store.mapListData);

  const onMarkerClick = (item) => {
    dispatch(
      getMapListDataAction({
        query: myProps.query,
        dataset: 'centers',
        from: 1,
        size: item.count,
        filters: JSON.stringify({
          coordinates_str: item.key,
        }),
      }),
    );
  }

  const popUpData = (item) => {
    return (
      <Popup className="pimap-main map-center" minWidth={200} closeButton={false}>
        <div
          className="popUp-first"
          onClick={() => handleMapPop(item)}
          tabIndex={0}
          role="button"
          onKeyDown={null}
        >
          <Loader loading={mapListData.loading} error={mapListData.error}>
            {mapListData.data.data?.map((item) => {
              return (
                <div className="map-result-list-card">
                  <div className="card-meta">
                    <div className="source">
                      <a
                        className="source-img"
                        target="_blank"
                        href={`/search/deepdives?${stringify({
                          query: item.name,
                          id: item.id,
                          type: item.type,
                          currentTab: item.parent_flag ? 'parent_centers' : 'centers',
                        })}`}
                        rel="noreferrer noopener"
                      />
                    </div>
                  </div>
                  <div className="map-result-list-card-title">{item.name}</div>
                  <div className="map-result-list-card-content">
                    {item?.address ? (
                      <div className="map-location">
                        {item.address?.address_line1 && `${item.address?.address_line1} ,`}
                        {item.address?.address_line2 && `${item.address?.address_line2} ,`}
                        {item.address?.city && `${item.address?.city} ,`}
                        {item.address?.state && `${item.address?.state} ,`}
                        {item.address?.country && `${item.address?.country} ,`}
                        {item.address?.zip}
                      </div>
                    ) : (
                      <div className="map-location content-child no-data">-</div>
                    )}
                  </div>
                  <div className="map-result-list-card-rating">
                    <div className="rating">
                      <div className="reliability rating-sec">
                        <div className="rating-sec-title">Reliability</div>
                        <StarRatings
                          rating={item.reliability}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="map-result-list-card-content">
                    <div className="map-tag">
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag"># Industry Studies </div>
                          <div className="sizeOftag">{item.industry_studies}</div>
                        </div>
                      </Tag>
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">PXL # Studies </div>
                          <div className="sizeOftag">{item.parexel_studies}</div>
                        </div>
                      </Tag>
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Tier</div>
                          <div className="sizeOftag">{item.tier}</div>
                        </div>
                      </Tag>
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Total # PIs</div>
                          <div className="sizeOftag">{item.total_number_of_popis}</div>
                        </div>
                      </Tag>
                    </div>
                  </div>
                </div>
              );
            })}
          </Loader>
        </div>
      </Popup>
    );
  };

  return (
    <div id="map">
      {data && data.length
        ? (
          <MapContainer center={centerPosition} zoom={0} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
              minZoom={2}
              maxZoom={19}
            />
            {data.map((item) => {
              return item.active === true ? (
                <MyMarker
                  center={[item.lat !== '' && item.lat !== 'None' ? parseFloat(item.lat) : 0, item.lon !== '' && item.lon !== 'None' ? parseFloat(item.lon) : 0]}
                  className="circle-marker-map"
                  fillOpacity={0.5}
                  radius={8}
                >
                  {popUpData(item)}
                </MyMarker>
              ) : (
                <CircleMarker
                  center={[item.lat !== '' && item.lat !== 'None' ? parseFloat(item.lat) : 0, item.lon !== '' && item.lon !== 'None' ? parseFloat(item.lon) : 0]}
                  fillOpacity={0.5}
                  className="circle-marker-map"
                  radius={8}
                  eventHandlers={{ click: () => onMarkerClick(item) }}
                >
                  {popUpData(item)}
                  <Tooltip1
                    direction="top"
                    permanent
                    className="run-analysis-map-tooltip"
                  >
                    <span title={item.count}>
                      {item.count}
                    </span>
                  </Tooltip1>
                </CircleMarker>
              );
            })}
          </MapContainer>
        )
        : (
          <div className="no-data-found-msg">
            No relevant data found
          </div>
        )}
    </div>
  );
}
