import { loginFetchReducer } from './container/Login/logic';
import { logoutFetchReducer } from './container/Logout/logic';
import { notificationReducer, customNotificationReducer } from './container/Notification/logic';
import { sessionReducer } from './container/Status/logic';
import { modalReducer } from './container/Modal/logic';
import { getDeepdiveValuesReducer } from '../app/container/DeepDiveScreen/logic';
import { getDocProfileValuesReducer } from '../app/container/DoctorProfile/logic';
import { addProjectDetailReducer, updateProjectDetailReducer } from '../app/container/AddProjectName/logic';
import { getFilterValuesReducer } from '../app/container/AddFilters/logic'

export default {
  login: loginFetchReducer,
  logout: logoutFetchReducer,
  notification: notificationReducer,
  session: sessionReducer,
  modal: modalReducer,
  customNotification: customNotificationReducer,
  deepDive: getDeepdiveValuesReducer,
  docProfile: getDocProfileValuesReducer,
  addProjectDetails: addProjectDetailReducer,
  updateProjectDetails: updateProjectDetailReducer,
  getFilterValues: getFilterValuesReducer,
};
