import { GENERIC_ACTION } from '../../../app/container/SearchResults/logic'

const GET_PARENT_GRAPH_CENTER_DATA = 'GET_PARENT_GRAPH_CENTER_DATA'
const GET_PARENT_GRAPH_CENTER_DATA_MORE = 'GET_PARENT_GRAPH_CENTER_DATA_MORE'
const GET_PARENT_GRAPH_CENTER_DATA_MORE_SUCCESS = 'GET_PARENT_GRAPH_CENTER_DATA_MORE_SUCCESS'
const GET_PARENT_GRAPH_CENTER_DATA_SUCCESS = 'GET_PARENT_GRAPH_CENTER_DATA_SUCCESS'
const GET_PARENT_GRAPH_CENTER_DATA_FAILURE = 'GET_PARENT_GRAPH_CENTER_DATA_FAILURE'
const GET_PARENT_GRAPH_CENTER_DATA_RESET = 'GET_PARENT_GRAPH_CENTER_DATA_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getParentGraphCenterDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PARENT_GRAPH_CENTER_DATA,
      url: `${process.env.apiUrl}v0/institution/getCenterslist`,
      method: 'get',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getParentGraphCenterDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PARENT_GRAPH_CENTER_DATA_MORE,
    url: `${process.env.apiUrl}v0/institution/getCenterslist`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getParentGraphCenterDataResetAction = () => ({
  type: GET_PARENT_GRAPH_CENTER_DATA_RESET,
})

export function getParentGraphCenterDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_GRAPH_CENTER_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_GRAPH_CENTER_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PARENT_GRAPH_CENTER_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PARENT_GRAPH_CENTER_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_PARENT_GRAPH_CENTER_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
