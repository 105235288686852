import React, { useEffect, useRef } from 'react';
import { stringify } from 'query-string';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tag } from 'antd';
import {
  MapContainer, Popup, TileLayer, CircleMarker, Tooltip as Tooltip1,
} from '@monsonjeremy/react-leaflet';
import StarRatings from 'react-star-ratings';
import Loader from '../Loader';
import {
  getMapListDataAction,
} from '../../../app/container/SearchResults/logic';
import './map.scss';

const MyMarker = (props) => {
  const leafletRef = useRef();

  useEffect(() => {
    leafletRef.current.openPopup();
  }, []);

  return <CircleMarker ref={leafletRef} {...props} />;
};

export default function PIMap(myProps) {
  const dispatch = useDispatch();
  const { data } = myProps;
  const centerPosition = data && data.length ? [data[0].lat !== '' && data[0].lat !== 'None' ? parseFloat(data[0].lat) : 0, data[0].lon !== '' && data[0].lon !== 'None' ? parseFloat(data[0].lon) : 0] : [];
  const mapListData = useSelector((store) => store.mapListData);

  const onMarkerClick = (item) => {
    dispatch(
      getMapListDataAction({
        query: myProps.query,
        dataset: 'investigators',
        from: 1,
        size: item.count,
        filters: JSON.stringify({
          coordinates_str: item.key,
        }),
      }),
    );
  }


  const popUpData = () => {
    return (
      <Popup className="pimap-main" minWidth={200} closeButton={false}>
        <div
          className="popUp-first"
          // onClick={() => handleMapPop(item)}
          // tabIndex={0}
          // role="button"
          // onKeyDown={null}
        >
          <Loader loading={mapListData.loading} error={mapListData.error}>
            {mapListData.data.data?.map((item) => {
              return (
                <div className="map-result-list-card">
                  <div className="card-meta">
                    {
                      // <div className="fav">
                      //   <Bookmark type="investigators" isBookmarked={item.isBookmarked.flag} id={item.id} bookmark_id={item.isBookmarked.bookmark_id} />
                      // </div>
                    }
                    <div className="source">
                      <a
                        className="source-img"
                        target="_blank"
                        href={`/search/investigatorprofile?${stringify({
                          query: item.name,
                          id: item.id,
                          type: item.type,
                        })}`}
                        rel="noreferrer noopener"
                      />
                    </div>
                  </div>
                  <div className="map-result-list-card-title">{item.name}</div>
                  <div className="map-result-list-card-content">
                    <div className="map-location">{item.primary_organization.name}</div>
                    <div className="map-location">{item.primary_organization ? `${item.primary_organization.city}, ${item.primary_organization.state}, ${item.primary_organization.country}, ${item.primary_organization.zip}` : '-'}</div>
                  </div>
                  <div className="map-result-list-card-rating">
                    <div className="rating">
                      <div className="reliability rating-sec">
                        <div className="rating-sec-title">Reliability</div>
                        {item.reliability !== ''
                          ? (
                            <StarRatings
                              rating={parseInt(item.reliability, 10)}
                              starRatedColor="#8A0050"
                              numberOfStars={3}
                              starDimension="16px"
                              starSpacing="1px"
                              starEmptyColor="#D099B9"
                            />
                          )
                          : (
                            <div className="dash-nodata">-</div>
                          )}
                      </div>
                      <div className="reliability rating-sec">
                        <div className="rating-sec-title">Quality:</div>
                        {item.quality_rating !== ''
                          ? (
                            <StarRatings
                              rating={item.quality_rating}
                              starRatedColor="#8A0050"
                              numberOfStars={3}
                              starDimension="16px"
                              starSpacing="1px"
                              starEmptyColor="#D099B9"
                              // name='rating'
                            />
                          )
                          : (
                            <div className="dash-nodata">-</div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="map-result-list-card-content">
                    <div className="content-title">Specialities:</div>
                    {item.specialties && item.specialties.length
                      ? (
                        <div>{item.specialties.join('; ')}</div>
                      )
                      : (
                        <div className="dash-nodata">-</div>
                      )}
                  </div>
                  <div className="map-result-list-card-content">
                    <div className="content-title">Last Trial Start Date:</div>
                    {item.last_trial_start_date
                      ? (
                        <div>{item.last_trial_start_date}</div>
                      )
                      : (
                        <div className="dash-nodata">-</div>
                      )}
                  </div>
                  <div className="map-result-list-card-content">
                    {
                      <div className="map-tag">
                        <Tag>
                          <div className="details-abt-tags">
                            <div className="nameOfTag"># Industry Studies</div>
                            <div className="sizeOftag">{item.industry_studies}</div>
                          </div>
                        </Tag>
                        {item.tier !== ''
                          ? (
                            <Tag>
                              <div className="details-abt-tags">
                                <div className="nameOfTag">Tier</div>
                                <div className="sizeOftag">{item.tier}</div>
                              </div>
                            </Tag>
                          )
                          : null}
                        <Tag>
                          <div className="details-abt-tags">
                            <div className="nameOfTag" title="KOL scores are derived based on research/information authored by the investigator like publications, clinical trials, congress articles, patents, grants, guidelines etc. Each document is scored considering several factors like type of document, recency of the document, role of the investigator in a given document, source where the document is published etc. Scores for each individual document is aggregated and weighted by data class, to calculate the raw KOL score. The raw KOL score for the investigators is then numerically scaled between 0 to 100 considering rest of the investigators in LandSCAPE.">KOL Score</div>
                            <div className="sizeOftag">{item.cls_kol_score}</div>
                          </div>
                        </Tag>
                      </div>
                    }
                  </div>
                </div>
              );
            })}
          </Loader>
          {
            // <div className="popUp-second">
            //   <div className="name-wrapper">{item.name}</div>
            //   <div className="popUp-third">
            //     <span className="sub-content">Primary organization: &nbsp; {item.affiliated_site}</span>
            //   </div>
            //   {
            //     // <div className="popUp-third">
            //     //   Reliability: &nbsp;
            //     //   {calcStar(item.reliability)}
            //     // </div>
            //   }
            //   <div className="popUp-fourth">
            //     {
            //       // <Button>
            //       //   Total PIs &nbsp;
            //       //   {item.total_number_of_pis}
            //       // </Button>
            //     }
            //     <Button>
            //       <span className="bg-content-title">Paraxel # Studies &nbsp;</span>
            //       <span className="bg-content">{item.parexel_studies}</span>
            //     </Button>
            //     <Button>
            //       <span className="bg-content-title"># Industry Studies &nbsp;</span>
            //       <span className="bg-content">{item.industry_studies}</span>
            //     </Button>
            //   </div>
            //   <div className="popUp-fifth">
            //     <Button className="indication-button">
            //       <div className="indication-main-wrapper">
            //         <div className="indication-wrapper-one bg-content-title">Indications: &nbsp;</div>
            //         <Tooltip
            //           placement="bottom"
            //           title={
            //             item.indications && item.indications.length ? item.indications.join('; ') : ''
            //           }
            //         >
            //           <span className="indication-wrapper-two bg-content">
            //             {item.indications && item.indications.length
            //               ? item.indications.join('; ')
            //               : ''}
            //           </span>
            //         </Tooltip>
            //       </div>
            //     </Button>
            //   </div>
            // </div>
          }
        </div>
      </Popup>
    );
  };

  return (
    <div id="map">
      {data && data.length
        ? (
          <MapContainer center={centerPosition} zoom={0} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
              minZoom={2}
              maxZoom={19}
            />
            {data.map((item) => {
              return item.active === true ? (
                <MyMarker
                  center={[item.lat !== '' && item.lat !== 'None' ? parseFloat(item.lat) : 0, item.lon !== '' && item.lon !== 'None' ? parseFloat(item.lon) : 0]}
                  className="circle-marker-map"
                  fillOpacity={0.5}
                  radius={8}
                >
                  {popUpData()}
                </MyMarker>
              ) : (
                <CircleMarker
                  center={[item.lat !== '' && item.lat !== 'None' ? parseFloat(item.lat) : 0, item.lon !== '' && item.lon !== 'None' ? parseFloat(item.lon) : 0]}
                  fillOpacity={0.5}
                  className="circle-marker-map"
                  // pathOptions={{ color: 'yellow' }}
                  radius={8}
                  eventHandlers={{ click: () => onMarkerClick(item) }}
                >
                  {popUpData()}
                  <Tooltip1
                    direction="top"
                    // offset={[0, 20]}
                    // opacity={1}
                    permanent
                    className="run-analysis-map-tooltip"
                  >
                    <span title={item.count}>
                      {item.count}
                    </span>
                  </Tooltip1>
                </CircleMarker>
              );
            })}
          </MapContainer>
        )
        : (
          <div className="no-data-found-msg">
            No relevant data found
          </div>
        )}
    </div>
  );
}
