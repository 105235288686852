import React from 'react';
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import './linechart.scss';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-linechart">
        <div className="label bold-text">{label}</div>
        <div className="desc">{`# Studies: ${payload[0].value}`}</div>
      </div>
    );
  }
  return null;
};

function PlotLineChart(props) {
  const {
    data,
  } = props;
  return (
    <>
      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart data={data} margin={{ right: 40 }}>
          <CartesianGrid vertical={false} horizontal={false} strokeDasharray="4 4" stroke="#aab8c2" />
          <XAxis
            dataKey="key"
            interval="preserveStartEnd"
            label={{ value: 'Year', position: 'insideBottom', offset: 0 }}
            tick={{
              stroke: '#8A0050', strokeWidth: 0.75, fontSize: 11, fontWeight: 100,
            }}
          />
          <YAxis
            tick={{
              stroke: '#8A0050', strokeWidth: 0.75, fontSize: 11, fontWeight: 100,
            }}
            label={{ value: '# Studies', angle: -90, position: 'center' }}
            allowDecimals={false}
          />
          <Legend />
          <Tooltip content={<CustomTooltip />} />
          <Line dataKey={props.parentFlag ? 'value' : 'count'} type={props.flag === 'CapacityGraph' && 'monotone'} stroke="#8A0050" strokeDasharray={props.flag !== 'CapacityGraph' ? '3 3' : null} activeDot={{ r: 2 }} legendType="none" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

export default PlotLineChart;
