import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parse, stringify } from 'query-string';
import { Tag, Tooltip } from 'antd';
import StarRatings from 'react-star-ratings';
import { Bar } from 'react-chartjs-2';
import Comment from '../../../app/container/Comment';
import ScoreModal from '../../../app/container/ScoreModal';
import Bookmark from '../../components/Bookmark';
import InputWithCopy from '../../../app/container/CopyToClipboard';
import Loader from '../../components/Loader';
import LazyLoadDiv from '../../components/LazyLoadDiv';
import { getParentGraphCenterDataAction, getParentGraphCenterDataMoreAction } from './logic';

export default function ShowCentersData({ clickedData, filterOn, dataset }) {
  const dispatch = useDispatch();
  const [centerList, setCenterList] = useState([])
  const [onTierClicked, setOnTierClicked] = useState(false)
  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(1);
  const getParentGraphCenterDataResponse = useSelector((state) => state.getParentGraphCenterDataResponse)

  useEffect(() => {
    if (filterOn === 'parentCenterByIndication'){
        dispatch(getParentGraphCenterDataAction({
          id: parse(location.search).id,
          dataset,
          from: 1,
          size: 10,
          filters: JSON.stringify({
            indications: [clickedData.category],
          }),
        }))
    } else if (filterOn === 'parentCenterByTA'){
        dispatch(getParentGraphCenterDataAction({
            id: parse(location.search).id,
            dataset,
            from: 1,
            size: 10,
            filters: JSON.stringify({
                therapeutic_area: [clickedData.category],
            }),
          }))
    }
  }, [])

  const tagTitle = {
    tier: 'Tier',
    compliance: 'Compliance',
    alliance: 'Alliance type',
    indications: 'Indications',
    total_number_of_popis: 'Total PIs',
    industry_studies: 'Industry studies',
    interventional_studies: 'Interventional studies',
    observational_studies: 'Observational studies',
    parexel_studies: 'Parexel studies',
    enrollment_subjects: 'Enrolled subjects',
  };

  useEffect(() => {
    if (getParentGraphCenterDataResponse.flag) {
      setCenterList(getParentGraphCenterDataResponse.data.data)
      setTotal(getParentGraphCenterDataResponse.data.total)
    }
  }, [JSON.stringify(getParentGraphCenterDataResponse)])

  const getDatefromTimestamp = (d) => {
    return new Date(d * 1000).toUTCString().substring(4, 16)
  }

  const onTierClick = () => {
    setOnTierClicked(true)
  }

  const loadMoreRows = () => {
    dispatch(getParentGraphCenterDataMoreAction({
      id: parse(location.search).id,
      dataset,
      from: 1,
      size: 10,
      filters: JSON.stringify({
        indications: [clickedData.category],
      }),
    }))
    setFrom(from + 1);
  }

  const renderCenters = () => {
    return (
    <div>
        {centerList.length > 0 ? centerList?.map((itm) => {
            return (
                <div className="constituent-centers-tab">
                    <div
                        className={`${itm.parent_flag === true ? 'flag-active' : 'no-flag'} ${itm.is_invalid === true ? 'invalid-itm-card' : ''}`}
                    >
                        <div className="CardList">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        <span className="flag-icon-img" />
                                        <a
                                            target="_blank"
                                            href={`/search/deepdives?${stringify({
                                                ...parse(location.search),
                                                id: itm.id,
                                                type: itm.type,
                                                currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                                            })}`}
                                            rel="noopener noreferrer"
                                        >
                                            <Tooltip title={(
                                                <div>
                                                    <div>Last modified by: {itm.last_modified_by_user_name ? itm.last_modified_by_user_name : '-'}</div>
                                                    <div>Last modified on: {itm.last_modified_on ? getDatefromTimestamp(itm.last_modified_on) : '-'}</div>
                                                </div>
                                            )}
                                            >
                                                {itm.name}
                                            </Tooltip>
                                        </a>
                                    </div>

                                    <div className="card-meta">
                                        <div className="comments">
                                            <Comment type="list_site" siteId={itm.id} count={JSON.stringify(itm.comments)} />
                                        </div>
                                        <div className="fav">
                                            <Bookmark isBookmarked={itm.isBookmarked.flag} type="centers" id={itm.id} bookmark_id={itm.isBookmarked.bookmark_id} />
                                        </div>
                                        <Tooltip title="View Center/Institute">
                                            <div className="source">
                                                <a
                                                    target="_blank"
                                                    href={`/search/deepdives?${stringify({
                                                        ...parse(location.search),
                                                        id: itm.id,
                                                        type: itm.type,
                                                        currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                                                    })}`}
                                                    rel="noopener noreferrer"
                                                >
                                                    <div className="source-img" />
                                                </a>
                                            </div>
                                        </Tooltip>
                                    </div>

                                </div>
                                <div className="content">
                                    <div className="leftside">
                                        <div className="person-details">
                                            <div className="contact_name">
                                                <div className="title-child">Address:</div>
                                                {itm.address
                                                    ? (
                                                        <div className="content-child">{itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                                                            {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                                                            {itm?.address?.city && `${itm?.address?.city}, `}
                                                            {itm?.address?.state && `${itm?.address?.state}, `}
                                                            {itm?.address?.country && `${itm?.address?.country}, `}
                                                            {itm?.address?.zip}
                                                        </div>
                                                    )
                                                    : (
                                                        <div className="content-child no-data">No data available</div>
                                                    )}
                                            </div>
                                            <div className="emailofcontact">
                                                <div className="title-child">Email:</div>
                                                {itm.email && itm.email.length
                                                    ? (
                                                        <>
                                                            <div className="content-child">
                                                                {itm.email.join(', ')}
                                                                <InputWithCopy text={itm} type="email" />
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        <div className="content-child no-data">No data available</div>
                                                    )}
                                            </div>
                                            <div className="mobileOfcontact">
                                                <div className="title-child">Phone:</div>
                                                {itm.phone_number && itm.phone_number.length
                                                    ? (
                                                        <div className="content-child">{itm.phone_number.join(', ')}</div>
                                                    )
                                                    : (
                                                        <div className="content-child no-data">No data available</div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="tags-data">
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.tier}</div>
                                                    <div className="sizeOftag" role="presentation" onClick={onTierClick}>{itm.tier}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.alliance}</div>
                                                    <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.compliance}</div>
                                                    <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                                                    <div className="sizeOftag">{itm.total_number_of_popis}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.industry_studies}</div>
                                                    <div className="sizeOftag">{itm.industry_studies}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                                                    <div className="sizeOftag">{itm.interventional_studies}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.observational_studies}</div>
                                                    <div className="sizeOftag">{itm.observational_studies}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                                                    <div className="sizeOftag">{itm.parexel_studies}</div>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                                                    <span className="sizeOftag">
                                                        {itm.enrollment_subjects}
                                                    </span>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">{tagTitle.indications}</div>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            itm.indications && itm.indications.length
                                                                ? itm.indications.join('; ')
                                                                : '-'
                                                        }
                                                    >
                                                        <span className="sizeOftag">
                                                            {itm.indications && itm.indications.length
                                                                ? itm.indications.join('; ')
                                                                : '-'}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">Sources&nbsp;</div>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            itm.sources && itm.sources.length
                                                                ? itm.sources.join(', ')
                                                                : '-'
                                                        }
                                                    >
                                                        <span className="sizeOftag">
                                                            {itm.sources && itm.sources.length
                                                                ? itm.sources.join(', ')
                                                                : '-'}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </Tag>
                                            <Tag>
                                                <div className="details-abt-tags">
                                                    <div className="nameOfTag">Center type&nbsp;</div>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            itm.center_type
                                                                ? itm.center_type
                                                                : '-'
                                                        }
                                                    >
                                                        <span className="sizeOftag">
                                                            {itm.center_type
                                                                ? itm.center_type
                                                                : '-'}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </Tag>
                                            {itm.pxl_center_sims_ids
                                                ? (
                                                    <Tag>
                                                        <div className="details-abt-tags">
                                                            <div className="nameOfTag">SIMS IDs&nbsp;</div>
                                                            <Tooltip
                                                                placement="bottom"
                                                                title={
                                                                    itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                                                        ? itm.pxl_center_sims_ids.join(', ')
                                                                        : '-'
                                                                }
                                                            >
                                                                <span className="sizeOftag">
                                                                    {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length ? itm.pxl_center_sims_ids.join(', ') : '-'}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </Tag>
                                                )
                                                : null}
                                            {itm.pxl_center_cit_ids
                                                ? (
                                                    <Tag>
                                                        <div className="details-abt-tags">
                                                            <div className="nameOfTag">Citeline IDs&nbsp;</div>
                                                            <Tooltip
                                                                placement="bottom"
                                                                title={
                                                                    itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                                                        ? itm.pxl_center_cit_ids.join(', ')
                                                                        : '-'
                                                                }
                                                            >
                                                                <span className="sizeOftag">
                                                                    {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length ? itm.pxl_center_cit_ids.join(', ') : '-'}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </Tag>
                                                )
                                                : null}

                                            {itm.landscape_id
                                                ? (
                                                    <Tag>
                                                        <div className="details-abt-tags">
                                                            <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                                                            <Tooltip
                                                                placement="bottom"
                                                                title={
                                                                    itm.landscape_id ? itm.landscape_id
                                                                        : '-'
                                                                }
                                                            >
                                                                <span className="sizeOftag">
                                                                    {itm.landscape_id ? itm.landscape_id : '-'}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </Tag>
                                                )
                                                : null}
                                        </div>
                                        <div className="reliability-quality-rating">
                                            <div className="reliability rating-sec">
                                                <div className="rating-sec-title">Reliability</div>
                                                {itm.reliability && itm.reliability !== ''
                                                    ? (
                                                        <StarRatings
                                                            rating={itm.reliability}
                                                            starRatedColor="#8A0050"
                                                            numberOfStars={3}
                                                            starDimension="16px"
                                                            starSpacing="1px"
                                                            starEmptyColor="#D099B9"
                                                        />
                                                    )
                                                    : (
                                                        <div className="content-child no-data">&nbsp;No data available</div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    {onTierClicked && <div><ScoreModal onItmClicked={onTierClicked} setOnItmClicked={setOnTierClicked} data={itm} flag="site-tier" /></div>}
                                </div>
                            </div>
                            {onTierClicked && <div><ScoreModal onItmClicked={onTierClicked} setOnItmClicked={setOnTierClicked} data={itm} flag="site-tier" /></div>}
                            {/* <div>
                                {itm.census_data ? Object.keys(itm.census_data).length ? <Bar options={options} data={renderGraph(itm)} /> : <div className="no-data-inside">No relevant data found</div> : null}
                            </div> */}
                        </div>
                    </div>
                </div>
            );
        }) : <div className="no-data-found-msg">No relevant data found</div>}
    </div>
    )
  }

  return (
    <Loader loading={getParentGraphCenterDataResponse.loading} error={getParentGraphCenterDataResponse.error}>
      <div>
        {getParentGraphCenterDataResponse.flag && centerList.length
          ? (
            <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
              <div className="show-doc-data">
                Showing<span className="show-count">{centerList.length}</span> out of
                <span className="show-count-number">{getParentGraphCenterDataResponse.data.total}</span>studies
              </div>
            </div>
          )
          : null}
      </div>
      <LazyLoadDiv
        className="card-list scrollbar"
        id="trialsatsite-list"
        total={total}
        currentTotal={(centerList || []).length}
        loadMoreRows={() => loadMoreRows()}
        height="calc(100vh - 380px)"
      >
        {renderCenters()}
      </LazyLoadDiv>
    </Loader>
  )
}
