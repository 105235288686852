import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ModalComponent from '../../components/ModalComponent'
import { modalCloseAction } from './logic'
import Login from '../Login'
import RequestInvite from '../RequestInvite'

const Modal = ({ app }) => {
  const {
    dialog, open, size, className, type, data,
  } = useSelector((state) => state.modal)
  const dispatch = useDispatch()
  const renderType = () => {
    switch (type) {
      case 'login':
        return <Login data={data} height={500} app={app} />
      case 'request_invite':
        return <RequestInvite data={data} height={350} />
      default:
        return null
    }
  }
  return (
    <ModalComponent dialog={dialog} open={open} onClose={() => dispatch(modalCloseAction())} size={size} className={className || ''}>
      {renderType()}
    </ModalComponent>
  )
}

Modal.propTypes = {
  app: PropTypes.string,
}

Modal.defaultProps = {
  app: process.env.ssoAppName || 'ontosight-acuo',
}

export default Modal
