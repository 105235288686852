import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../../app/container/SearchResults/logic'

const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
const DELETE_NOTIFICATION_RESET = 'DELETE_NOTIFICATION_RESET'
const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const deleteNotificationAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_NOTIFICATION,
      url: `${process.env.apiUrl}v0/Notification/deleteNotification?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Notification deleted successfully',
    hideNotification: false,
  })
}

export const deleteNotificationResetAction = () => ({
  type: DELETE_NOTIFICATION_RESET,
})

export function deleteNotificationReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_NOTIFICATION: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_NOTIFICATION_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_NOTIFICATION_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_NOTIFICATION_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
