import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import { setSession, removeSession } from './logic'
import { getCookie, setAnalytics } from '../../../utils';
import { modalCloseAction } from '../Modal/logic'

const Status = () => {
  const { type, status } = useSelector((state) => state.notification)
  const session = useSelector((state) => state.session)
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const activityFlow = () => {
    if (type === 'LOGOUT_SUCCESS') {
      setAnalytics('logout', '', '')
      dispatch(removeSession())
      history.replace(process.env.mainRoute);
    } else if (type === 'LOGIN_SUCCESS') {
      dispatch(setSession(getCookie('accessToken')))
      if (process.env.isAuthModal) {
        dispatch(modalCloseAction())
      }
      setAnalytics('login', '', '')
      const { redirect_url } = parse(location.search)
      history.replace(redirect_url || process.env.redirectRoute);
    } else if (type.indexOf('_FAILURE') > -1) {
      if (status === 401 && type !== 'LOGIN_FAILURE') {
        dispatch(removeSession())
        history.replace(`${process.env.mainRoute}?${stringify({ redirect_url: `${location.pathname}${location.search}${location.hash}` })}`);
      }
    }
  }

  useEffect(() => {
    if (session) {
      setAnalytics('checkLogin', '', '')
    }
  }, [])

  useEffect(() => {
    if (type !== 'cancel') {
      activityFlow()
    }
  }, [type])
  return null
}

export default Status
