import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify'
import { NOTIFICATION_CANCEL } from './logic'

const Notification = () => {
  const {
    type, message, notificationType, showNotification,
  } = useSelector((state) => state.notification)
  useEffect(() => {
    if (type !== NOTIFICATION_CANCEL && showNotification) {
      toast(message, {
        position: 'top-center',
        autoClose: 4000,
        pauseOnHover: false,
        draggable: true,
        newestOnTop: true,
        type: notificationType,
        hideProgressBar: true,
        closeButton: false,
        pauseOnFocusLoss: false,
      })
    }
  }, [type])
  return (
    <ToastContainer />
  )
}

export default Notification
