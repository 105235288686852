import { stringify } from 'query-string';
import {
  map, catchError, mergeMap, takeUntil,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCallwithoutCT } from '../../../utils';
import { GENERIC_ACTION } from '../SearchResults/logic';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const UPLOAD_SPONSORS_FILE = 'UPLOAD_SPONSORS_FILE';
const UPLOAD_SPONSORS_FILE_CANCEL = 'UPLOAD_SPONSORS_FILE_CANCEL';
const UPLOAD_SPONSORS_FILE_RESET = 'UPLOAD_SPONSORS_FILE_RESET';
const UPLOAD_SPONSORS_FILE_SUCCESS = 'UPLOAD_SPONSORS_FILE_SUCCESS';
const UPLOAD_SPONSORS_FILE_FAILURE = 'UPLOAD_SPONSORS_FILE_FAILURE';

const ADD_COLUMN_MAPPING = 'ADD_COLUMN_MAPPING'
const ADD_COLUMN_MAPPING_SUCCESS = 'ADD_COLUMN_MAPPING_SUCCESS'
const ADD_COLUMN_MAPPING_FAILURE = 'ADD_COLUMN_MAPPING_FAILURE'

const GET_UPLOADED_PIS_META = 'GET_UPLOADED_PIS_META'
const GET_UPLOADED_PIS_META_SUCCESS = 'GET_UPLOADED_PIS_META_SUCCESS'
const GET_UPLOADED_PIS_META_RESET = 'GET_UPLOADED_PIS_META_RESET'
const GET_UPLOADED_PIS_META_FAILURE = 'GET_UPLOADED_PIS_META_FAILURE'

const GET_UPLOADED_PIS_LIST = 'GET_UPLOADED_PIS_LIST'
const GET_UPLOADED_PIS_LIST_SUCCESS = 'GET_UPLOADED_PIS_LIST_SUCCESS'
const GET_UPLOADED_PIS_LIST_RESET = 'GET_UPLOADED_PIS_LIST_RESET'
const GET_UPLOADED_PIS_LIST_FAILURE = 'GET_UPLOADED_PIS_LIST_FAILURE'

const UPDATE_UPLOADED_PI = 'UPDATE_UPLOADED_PI'
const UPDATE_UPLOADED_PI_SUCCESS = 'UPDATE_UPLOADED_PI_SUCCESS'
const UPDATE_UPLOADED_PI_RESET = 'UPDATE_UPLOADED_PI_RESET'
const UPDATE_UPLOADED_PI_FAILURE = 'UPDATE_UPLOADED_PI_FAILURE'

const ADD_UPLOADED_PIS = 'ADD_UPLOADED_PIS'
const ADD_UPLOADED_PIS_SUCCESS = 'ADD_UPLOADED_PIS_SUCCESS'
const ADD_UPLOADED_PIS_FAILURE = 'ADD_UPLOADED_PIS_FAILURE'
const ADD_UPLOADED_PIS_RESET = 'ADD_UPLOADED_PIS_RESET'

// Auto Complete cancel action
export const uploadSponsorsFileCancelAction = () => ({
  type: UPLOAD_SPONSORS_FILE_CANCEL,
});

// Auto Complete action
export const uploadSponsorsFileAction = (payload) => ({
  type: UPLOAD_SPONSORS_FILE,
  payload,
});

// Auto Complete Success action
const uploadSponsorsFileSuccess = (payload) => ({
  type: UPLOAD_SPONSORS_FILE_SUCCESS,
  payload,
  showNotification: false,
  // message: 'File uploaded successfully',
});

// Add project reset action
export const uploadSponsorsFileResetAction = () => ({
  type: UPLOAD_SPONSORS_FILE_RESET,
});

export const uploadSponsorsFileEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_SPONSORS_FILE),
  mergeMap((action) => ajax(
    apiCallwithoutCT(
      `${process.env.apiUrl}v0/search/uploadpis?${stringify(action.payload.params)}`,
      'POST',
      action.payload.body,
    ),
  ).pipe(
    map((res) => uploadSponsorsFileSuccess(res)),
    takeUntil(actions$.pipe(ofType(UPLOAD_SPONSORS_FILE_CANCEL))),
    catchError((err) => of({
      type: UPLOAD_SPONSORS_FILE_FAILURE,
      payload: err,
    })),
  )),
);

export function uploadSponsorsFileReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPLOAD_SPONSORS_FILE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case UPLOAD_SPONSORS_FILE_SUCCESS: {
      return {
        data: action.payload.response,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case UPLOAD_SPONSORS_FILE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case UPLOAD_SPONSORS_FILE_FAILURE: {
      return {
        data: action.payload.response,
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

export const addColumnMappingAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: ADD_COLUMN_MAPPING,
    url: `${process.env.apiUrl}v0/search/columnmap?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'LandSCAPE has started the process to analyze each investigator form the uploaded spreadsheet. You will be notified via email once the process is completed and the results are available for your review.',
  hideNotification: false, // hide notification on error
})

export function addColumnMappingReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_COLUMN_MAPPING: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADD_COLUMN_MAPPING_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADD_COLUMN_MAPPING_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getUploadedPIsMetaAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_UPLOADED_PIS_META,
    url: `${process.env.apiUrl}v0/search/getuploadpismeta`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getUploadedPIsMetaResetAction = () => ({
  type: GET_UPLOADED_PIS_META_RESET,
})

export function getUploadedPIsMetaReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_UPLOADED_PIS_META: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_UPLOADED_PIS_META_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_UPLOADED_PIS_META_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_UPLOADED_PIS_META_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export const getUploadedPIsListAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_UPLOADED_PIS_LIST,
    url: `${process.env.apiUrl}v0/search/getuploadpislist`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getUploadedPIsListResetAction = () => ({
  type: GET_UPLOADED_PIS_LIST_RESET,
})

export function getUploadedPIsListReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_UPLOADED_PIS_LIST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_UPLOADED_PIS_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_UPLOADED_PIS_LIST_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_UPLOADED_PIS_LIST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export const updateUploadedPIAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: UPDATE_UPLOADED_PI,
    url: `${process.env.apiUrl}v0/search/updateuploadedpi?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const updateUploadedPIResetAction = () => ({
  type: UPDATE_UPLOADED_PI_RESET,
})

export function updateUploadedPIReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_UPLOADED_PI: {
      return {
        data: {
          ...state.data,
        },
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_UPLOADED_PI_SUCCESS: {
      return {
        data: {
          ...state.data,
          [action.meta.key]: action.payload,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_UPLOADED_PI_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case UPDATE_UPLOADED_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export const addUploadedPIsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: ADD_UPLOADED_PIS,
    url: `${process.env.apiUrl}v0/search/adduploadedpis`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'LandSCAPE has started the process to add selected investigators in the Project. You will be notified via email once the process is completed and the results are available for your review.',
  hideNotification: false, // hide notification on error
})

export const addUploadedPIsResetAction = () => ({
  type: ADD_UPLOADED_PIS_RESET,
})

export function addUploadedPIsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_UPLOADED_PIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADD_UPLOADED_PIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADD_UPLOADED_PIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case ADD_UPLOADED_PIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
