import React from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '../ErrorBoundary'
import NoDataFound from '../NoDataFound'

const Loader = (props) => {
  if (props.loading) {
    return (
      <div className="loader" style={{ height: props.height, width: props.width, ...props.style }}>
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
        {props.loaderText
          ? <div className="loader-text">{props.loaderText}</div>
          : null}
      </div>
    )
  }
  if (props.error) {
    const style = {
      height: props.height,
      width: props.width,
      ...props.style,
      textAlign: 'center',
    }
    return (
      <div style={style} className="loader">
        {props.errorMessage || 'Oops! There has been an issue. Re-try in some time.'}
      </div>
    )
  }
  if (props.noData) {
    return <NoDataFound message={props.message} className={props.className ? `loader ${props.className}` : 'loader'} style={{ height: props.height, width: props.width, ...props.style }} />
  }
  return (
    <ErrorBoundary height={props.height} width={props.width}>
      {props.children}
    </ErrorBoundary>
  )
}

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  noData: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  message: PropTypes.string,
  errorMessage: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}
Loader.defaultProps = {
  height: 'calc(100vh - 110px)',
  width: '100%',
  noData: false,
  children: null,
  message: '',
  errorMessage: '',
  style: {},
  className: '',
}

export default Loader
