import React from 'react'
import PropTypes from 'prop-types'
import OutsideClick from '../OutsideClick'

const sizes = ['x-small', 'small', 'medium', 'large']

const ModalComponent = ({
  size, open, dialog, children, onClose,
}) => {
  const checkSize = sizes.indexOf(size) > -1 ? sizes[sizes.indexOf(size)] : 'medium'
  return (
    <div className="del-modal">
      {open ? <div className="special-overlay" /> : null}
      <OutsideClick className="del-modal-content" onClickOutside={() => onClose()}>
        {
          open && (
            <div className={`special-modal special-modal-${checkSize} ${dialog ? 'special-modal-dialog' : ''}`}>
              <div className="special-modal-close" role="presentation" onClick={() => onClose()}>&#10005;</div>
              {children}
            </div>
          )
        }
      </OutsideClick>
    </div>
  )
}

ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  size: PropTypes.string,
  dialog: PropTypes.bool,
}

ModalComponent.defaultProps = {
  children: null,
  size: 'medium',
  dialog: false,
}

export default ModalComponent
