import React, { useEffect } from 'react';
import * as d3 from 'd3-5.16.0';
import './piechart.scss';

function PieChart(props) {
  const {
    data, outerRadius, innerRadius, idName, hideLegend, pieWidth, flag,
  } = props;
  const nameID = `#${idName}`;

  useEffect(() => {
    drawChart();
  }, [data]);

  const margin = {
    top: 10,
    right: 150,
    bottom: 10,
    left: 10,
  };

  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;

  const colorScale = d3.scaleOrdinal().domain([0, data.length]).range(['#d3d800', '#e4e766', '#4e565b',
    '#637b88',
    '#a6aaad',
    '#b8bbbd',
    '#8A0050',
    '#ad4c84',
    '#c47fa7',
    '#d099b9',
    '#003e6e',
    '#4c7799',
    '#668ba8',
    '#7f9eb6',
    '#99b1c5']);

  function drawChart() {
    // Remove the old svg
    d3.select(nameID).select('svg').remove();

    // Create new svg
    const svg = d3
      .select(nameID)
      .append('svg')
      .attr('width', pieWidth || (hideLegend ? width : 360))
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const arcGenerator = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    const pieGenerator = d3
      .pie()
      .padAngle(0)
      .value((d) => d.value);

    const arc = svg.selectAll().data(pieGenerator(data.filter((d) => (d.value !== 0)))).enter();

    arc
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (_, i) => colorScale(i))
      .style('stroke', '#ffffff')
      .style('stroke-width', 0)
      .on('mouseover', (d) => {
        d3.select(`#${idName}-tooltip`)
          .style('opacity', 1)
          .style('display', 'block')
          .select('#value')
          .text(d.value);

        d3.select(`#${idName}-tooltip .label-name`).text(`${d.data.category}: `);
        d3.select(`#${idName}-tooltip .label-value`).text(
          ` ${d.data.value}${idName !== 'analytics' && idName !== 'experience' && idName !== 'agevsstudies' && idName !== 'agevsstudiesmodal' && idName !== 'indicationPieChart' && flag !== false ? '%' : ''}`,
        );
      })
      .on('mouseout', () => {
        // Hide the tooltip
        d3.select(`#${idName}-tooltip`).style('opacity', 1).style('display', 'none');
      });

    // Append text labels
    arc
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      // .text((d) => d.data.category)
      .style('fill', (_, i) => colorScale(data.length - i))
      .attr('transform', (d) => {
        const [x, y] = arcGenerator.centroid(d);
        return `translate(${x}, ${y})`;
      });

    // Append Legends
    if (!hideLegend) {
      const legendG = arc.append('g')
        .attr('transform', (d, i) => (`translate(${width - 200},${((i - 1) * 11)})`))
        .attr('class', 'legend');

      legendG.append('rect')
        .attr('width', 10)
        .attr('height', 10)
        .attr('fill', (d, i) => (colorScale(i)));

      legendG.append('text')
        .text((d) => (`${d.data.category}`))
        .style('font-size', 12)
        .attr('y', 10)
        .attr('x', 15);
    }
  }

  return (
    <>
      <div id={idName}>
        <div className="pie-chart-tooltip" id={`${idName}-tooltip`}>
          <div className="tooltip-name">
            <div className="label-name">Category:</div>
            <div className="label-value">label</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PieChart;
