import {
  map, catchError, mergeMap, takeUntil,
} from 'rxjs/operators';
import { stringify, parse } from 'query-string'
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION';

const datasetType = {
  investigators: 'Investigator',
  projects: 'Project',
  analysis: 'Analysis',
  centers: parse(location.search).currentTab === 'parent_centers' ? 'Parent Institution' : 'Center',
}

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const CREATE_BOOKMARK = 'CREATE_BOOKMARK';
const CREATE_BOOKMARK_SUCCESS = 'CREATE_BOOKMARK_SUCCESS';
const CREATE_BOOKMARK_FAILURE = 'CREATE_BOOKMARK_FAILURE';
const CREATE_BOOKMARK_RESET = 'CREATE_BOOKMARK_RESET';

const DELETE_BOOKMARK = 'DELETE_BOOKMARK'
const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS'
const DELETE_BOOKMARK_FAILURE = 'DELETE_BOOKMARK_FAILURE'
const DELETE_BOOKMARK_RESET = 'DELETE_BOOKMARK_RESET'

// create bookmark action
export const createBookmarkAction = (payload) => ({
  type: CREATE_BOOKMARK,
  payload,
});

// reset bookmark
export const createBookmarkResetAction = () => ({
  type: CREATE_BOOKMARK_RESET,
});

// mark favourite success action
const createBookmarkSuccess = (payload, type) => ({
  type: CREATE_BOOKMARK_SUCCESS,
  payload,
  showNotification: true,
  message: `${datasetType[type]} marked as favourite`,
});

// reset remove bookmark action
export const deleteBookmarkResetAction = () => ({
  type: DELETE_BOOKMARK_RESET,
});

// call createBookmark api
export const createBookmarkEpic = (actions$) => actions$.pipe(
  ofType(CREATE_BOOKMARK),
  mergeMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}v0/bookmark/createbookmark`,
      'POST',
      action.payload.body,
      true,
    ),
  ).pipe(
    map((res) => createBookmarkSuccess(res, action.payload.body.type)),
    takeUntil(actions$.pipe(ofType(CREATE_BOOKMARK_FAILURE))),
    catchError((err) => of({
      type: CREATE_BOOKMARK_FAILURE,
      payload: err,
    })),
  )),
);

export const deleteBookmarkAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_BOOKMARK,
      url: `${process.env.apiUrl}v0/bookmark/deleteBookmark?${stringify(payload.params)}`,
      method: 'DELETE',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Removed from favourites',
    hideNotification: false,
  })
}
// create bookmark reducer
export function createBookmarkReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_BOOKMARK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case CREATE_BOOKMARK_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case CREATE_BOOKMARK_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case CREATE_BOOKMARK_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

// delete bookmark reducer
export function deleteBookmarkReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_BOOKMARK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_BOOKMARK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_BOOKMARK_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_BOOKMARK_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
