import {
  map, takeUntil, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall, setCookie } from '../../../utils';

const LOGIN = 'LOGIN';
const LOGIN_CANCEL = 'LOGIN_CANCEL';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGIN_RESET = 'LOGIN_RESET';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
  status: 0,
};

export const loginResetAction = () => ({
  type: LOGIN_RESET,
});

export const loginFetchAction = (payload) => ({
  type: LOGIN,
  payload,
});

const loginFetchSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFetchCancel = (payload) => ({
  type: LOGIN_CANCEL,
  payload,
});

export const loginFetchEpic = (actions$) => actions$.pipe(
  ofType(LOGIN),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/auth/callback`, 'get', action.payload.params, false)).pipe(
    map((res) => loginFetchSuccess(res)),
    takeUntil(actions$.pipe(ofType(LOGIN_CANCEL))),
    catchError((err) => of({
      type: LOGIN_FAILURE,
      payload: err,
      showNotification: true,
    })),
  )),
);

// Auth reducer updates both login and logout
export function loginFetchReducer(state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case LOGIN_RESET: {
      return INITIAL_STATE
    }
    case LOGIN: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false,
        status: 0,
      };
    }
    case LOGIN_SUCCESS: {
      const userName = `${action.payload.response.profile.id}`
      const bearer = `Bearer ${action.payload.response.accessToken}`
      setCookie(JSON.stringify(action.payload.response.permissions), 'permissions')
      setCookie(action.payload.response.refreshToken, 'refreshToken')
      setCookie(bearer, 'accessToken')
      setCookie(action.payload.response.profile.first_name, 'name')
      setCookie(action.payload.response.profile.last_name, 'lastName')
      setCookie(userName, 'userName')
      setCookie(action.payload.response.profile.organization || 'individual', 'org')
      if (action.payload.response.is_trial_login) {
        setCookie(true, 'trialUser')
        localStorage.setItem('expiry-trial-period', action.payload.response.expiry_time)
      }
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
        status: action.payload.status || 200,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false,
        status: action.payload.status || 400,
      };
    }
    default:
      return state;
  }
}
