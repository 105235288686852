import React from 'react';
import './barchart.scss';

export default ({ hoveredBar }) => {
  return (
    <div className={hoveredBar.category ? 'Tooltip' : 'Tooltip Tooltip-category'}>
      <div>
        {hoveredBar.category ? (
          <span>
            {hoveredBar.category}:&nbsp;{hoveredBar.value}{' '}
          </span>
        ) : (
          <span>
            {hoveredBar.key}:&nbsp;{hoveredBar.count}
          </span>
        )}
      </div>
    </div>
  );
};
