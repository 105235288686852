import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { parse } from 'query-string';
import { getStudiesDataAction, getStudiesDataMoreAction, getStudiesDataResetAction } from '../InvestigatorStudies/logic';
import ReadMore from '../../../common/components/ReadMore';
import Loader from '../../../common/components/Loader';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';

export default function StudyModal({
  onItmClicked, setOnItmClicked, clickedPId, flag, analysisId,
}) {
  const dispatch = useDispatch();
  const studiesData = useSelector((store) => store.studiesData);
  const [studiesList, setStudiesList] = useState([]);
  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(1);

  useEffect(() => {
    return () => {
      dispatch(getStudiesDataResetAction());
    }
  }, []);

  useEffect(() => {
    if (clickedPId) {
      if (flag === 'competing') {
        setFrom(1);
        dispatch(getStudiesDataAction({
          id: clickedPId,
          dataset: 'investigators',
          from: 1,
          size: 10,
          study_type: 'competing_studies',
          study_for: 'Project_view',
          project_id: parse(location.search).project_id,
          analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
        }))
      } else if (flag === 'industry') {
        setFrom(1);
        dispatch(getStudiesDataAction({
          id: clickedPId,
          dummy: clickedPId,
          dataset: 'investigators',
          // industry_studies_source: industryStudiesSource,
          from: 1,
          size: 10,
          study_type: 'industry_studies',
          study_for: 'Project_view',
          project_id: parse(location.search).project_id,
          analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
        }))
      } else if (flag === 'pxl') {
        setFrom(1);
        const key = 'sources'
        const temp = {}
        temp[key] = ['SIMS']
        dispatch(getStudiesDataAction({
          id: clickedPId,
          dataset: 'investigators',
          from: 1,
          size: 10,
          // study_type: 'pxl_studies',
          study_for: 'Project_view',
          project_id: parse(location.search).project_id,
          filters: JSON.stringify(temp),
          analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
        }))
      } else if (flag === 'pxl-search-specific') {
        setFrom(1);
        dispatch(getStudiesDataAction({
          id: clickedPId,
          dataset: 'investigators',
          from: 1,
          size: 10,
          study_type: 'pxl_studies',
          study_for: 'Project_view',
          project_id: parse(location.search).project_id,
          analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
        }))
      }
    }
  }, [clickedPId])

  useEffect(() => {
    if (studiesData.flag) {
      setStudiesList(studiesData.data.data);
      setTotal(studiesData.data.total);
    }
  }, [JSON.stringify(studiesData)])

  const renderStudies = () => {
    if (studiesData.flag && studiesList.length) {
      return studiesList.map((studyItm, index) => (
        <div className="study-card-wrap" key={index}>
          <div className="card">
            <div className="card-title">{studyItm.title}</div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Status:</div>
                <div className="desc">{studyItm.trial_status}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Phase:</div>
                <div className="desc">{studyItm.phase}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Trial ID:</div>
                <div className="desc">{studyItm.trial_id}</div>
              </div>
            </div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Intervention:</div>
                <div className="desc">{studyItm.interventions && studyItm.interventions.length ? studyItm.interventions.join('; ') : '-'}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Indication:</div>
                <div className="desc">{studyItm.indications && studyItm.indications.length ? studyItm.indications.join('; ') : '-'}</div>
              </div>
            </div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Centers:</div>
                <div className="desc">
                  <ReadMore type="text" limit={100}>
                    {studyItm.centers && studyItm.centers.length ? studyItm.centers.join('; ') : '-'}
                  </ReadMore>
                </div>
              </div>
              <div className="card-content-data">
                <div className="title">Sponsor:</div>
                <div className="desc">{studyItm.sponsors && studyItm.sponsors.length ? studyItm.sponsors.join('; ') : '-'}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Total Payment:</div>
                <div className="desc">{studyItm.total_payments ? studyItm.total_payments : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
    if (studiesData.flag && !studiesList.length) {
      return (
        <div className="no-data-found-msg">
          No relevant data found
        </div>
      );
    }
    return null;
  }

  const handleCancel = () => {
    setOnItmClicked(false)
  }
  const handleOk = () => {
    setOnItmClicked(false)
  }

  const loadMoreRows = () => {
    if (flag === 'industry') {
      dispatch(getStudiesDataMoreAction({
        id: clickedPId,
        dataset: 'investigators',
        // industry_studies_source: industryStudiesSource,
        from: from + 1,
        size: 10,
        study_type: 'industry_studies',
        project_id: parse(location.search).project_id,
        analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
      }))
      setFrom((f) => f + 1);
    } else if (flag === 'competing') {
      dispatch(getStudiesDataMoreAction({
        id: clickedPId,
        dummy: clickedPId,
        dataset: 'investigators',
        from: from + 1,
        size: 10,
        study_type: 'competing_studies',
        project_id: parse(location.search).project_id,
        analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
      }))
      setFrom((f) => f + 1);
    } else if (flag === 'pxl') {
      const key = 'sources'
      const temp = {}
      temp[key] = ['SIMS']
      dispatch(getStudiesDataMoreAction({
        id: clickedPId,
        dataset: 'investigators',
        from: from + 1,
        size: 10,
        // study_type: 'pxl_studies',
        filters: JSON.stringify(temp),
        project_id: parse(location.search).project_id,
        analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
      }))
      setFrom((f) => f + 1);
    } else if (flag === 'pxl-search-specific') {
      dispatch(getStudiesDataMoreAction({
        id: clickedPId,
        dataset: 'investigators',
        from: from + 1,
        size: 10,
        study_type: 'pxl_studies',
        study_for: 'Project_view',
        project_id: parse(location.search).project_id,
        analysis_id: parse(location.search).analysis_id !== undefined ? parse(location.search).analysis_id : analysisId,
      }))
      setFrom((f) => f + 1);
    }
  }

  return (
    <div>
      <Modal
        style={{ marginTop: '60px', width: '100%' }}
        title={flag === 'competing' ? 'Competing studies'
          : (flag === 'industry' ? 'Industry studies' : flag === 'pxl' ? '# PXL Studies (General)' : '# PXL Studies (Search Specific)')}
        visible={onItmClicked}
        // okText="Ok"
        className="comment-type-modal-wrapper competing-studies show-data-modal"
        onCancel={handleCancel}
        onOk={handleOk}
        // okType="primary"
        destroyOnClose
      >
        {studiesData.flag && studiesList.length
          ? (
            <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
              <div className="show-doc-data">
                Showing<span className="show-count">{studiesList.length}</span> out of
                <span className="show-count-number">{studiesData.data.total}</span>studies
              </div>
            </div>
          )
          : null}
        <div className="investigator-studies-list-wrap">
          <Loader loading={studiesData.loading} error={studiesData.error}>
            <LazyLoadDiv
              className="card-list scrollbar"
              id="industry_list"
              total={total}
              currentTotal={(studiesList || []).length}
              loadMoreRows={() => loadMoreRows()}
              height="calc(100vh - 380px)"
            >
              <div>
                {renderStudies()}
              </div>
            </LazyLoadDiv>
          </Loader>
        </div>
      </Modal>

    </div>
  )
}
