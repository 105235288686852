import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import rootReducer from './reducers';
import rootEpic from './epics';
import Main from './route';
import notificationMiddleWare from '../common/middleware';
import Notification from '../common/container/Notification';
import Status from '../common/container/Status';
import Modal from '../common/container/Modal';
import Footer from '../common/container/Footer';
import CookieSupport from '../common/components/CookieSupport';
import { eliminateCookies } from '../utils';

const epicMiddleware = createEpicMiddleware();
const store = createStore(
  rootReducer || {},
  applyMiddleware(epicMiddleware, notificationMiddleWare),
);

epicMiddleware.run(rootEpic);

const RootComponent = () => {
  try {
    if (feedback) {
      window.feedback = feedback
    }
  } catch (error) {
    window.feedback = null
  }

  useEffect(() => {
    eliminateCookies();
  }, []);

  useEffect(() => {
    eliminateCookies();
  }, [document.cookie]);

  return (
    <Provider store={store}>
      <Router>
        <div className="landscape-app">
          <Status />
          <Main />
          <Notification />
          <Modal />
          <Footer />
          <CookieSupport />
        </div>
      </Router>
    </Provider>
  )
}

export default RootComponent;
