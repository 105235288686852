import { Avatar } from 'antd';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { stringify } from 'query-string';
import { getCookie, getFormattedDate } from '../../../utils';
import Loader from '../../components/Loader';
import LazyLoadDiv from '../../components/LazyLoadDiv';
import { getTaggedCommentsMoreAction } from '../../../app/container/Search/logic';

const TaggedComments = () => {
  const dispatch = useDispatch()
  const taggedCommentsData = useSelector((store) => store.taggedCommentsData)
  const [commentsData, setCommentsData] = useState([])
  const [commentsFrom, setCommentsFrom] = useState(1);

  useEffect(() => {
    if (taggedCommentsData.flag) {
      setCommentsData(taggedCommentsData?.data)
      setCommentsFrom(commentsFrom + 1)
    }
  }, [JSON.stringify(taggedCommentsData)])

  const getContent = (itm) => {
    if (itm.tagged_user_names) {
      let txt = itm.comment
      itm.tagged_user_names.map((uname) => {
        // console.log(first)
        if (uname === getCookie('name')) {
          txt = txt.replace(`@${uname}`, `<div class="tagged-user">@${uname}</div>`)
        } else {
          txt = txt.replace(`@${uname}`, `<div class="text-highlight">@${uname}</div>`)
        }
        return null
      })
      return txt
    }
    return <p>{itm.comment}</p>
  }

  const getActionItems = (item) => {
    switch (item.type) {
      case 'pi': return (
        <>
          <span className="action-title">Project Name :</span> <span className="action-cont">this project</span>  <span className="sep-action">|</span>
          <span className="action-title">Investigator Name :</span>
          <span className="action-cont">
            <a
              className="pi-content-title"
              title={item?.tagged_on_name[0]}
              target="_blank"
              href={`/search/investigatorprofile?${stringify({
                id: item?.pi_id,
                type: 'PI',
              })}`}
              rel="noreferrer noopener"
            >
              {item?.tagged_on_name[0]}
            </a>
          </span>
        </>
      )
      case 'list_pi': return (
        <>
          <span className="action-title">Investigator Name :</span>
          <span className="action-cont">
            <a
              className="pi-content-title"
              title={item?.tagged_on_name[0]}
              target="_blank"
              href={`/search/investigatorprofile?${stringify({
                id: item?.pi_id,
                type: 'PI',
              })}`}
              rel="noreferrer noopener"
            >{item?.tagged_on_name[0]}
            </a>
          </span>
        </>
      )
      case 'projects': return (
        <>
          <span className="action-title">Project Name :</span> <span className="action-cont"><a>{item?.tagged_on_name[0]}</a></span>
        </>
      )

      case 'site': return (
        <>
          <span className="action-title">Project Name :</span> <span className="action-cont">this project</span>  <span className="sep-action">|</span>
          <span className="action-title">Center Name :</span>
          <span className="action-cont">
            <a
              className="card-content-title"
              title={item?.tagged_on_name[0]}
              target="_blank"
              href={`/search/deepdives?${stringify({
                id: item?.site_id,
                type: 'SITE',
                currentTab: item.parent_flag ? 'parent_centers' : 'centers',
              })}`}
              rel="noreferrer noopener"
            >
              {item?.tagged_on_name[0]}
            </a>
          </span>
        </>
      )
      case 'analysis': return (
        <>
          <span className="action-title">Analysis Name :</span> <span className="action-cont"><a>{item?.tagged_on_name[0]}</a></span>
        </>
      )

      case 'list_site': return (
        <>
          <span className="action-title">Center Name :</span>
          <span className="action-cont">
            <a
              className="card-content-title"
              title={item?.tagged_on_name[0]}
              target="_blank"
              href={`/search/deepdives?${stringify({
                id: item?.site_id,
                type: 'SITE',
                currentTab: item.parent_flag ? 'parent_centers' : 'centers',
              })}`}
              rel="noreferrer noopener"
            >
              {item?.tagged_on_name[0]}
            </a>
          </span>
        </>
      )

      default: return <>Action Not found</>
    }
  }

  const loadMoreTaggedComments = () => {
    dispatch(getTaggedCommentsMoreAction(
      {
        user_id: getCookie('userName'),
        from: commentsFrom,
        size: 10,
      },
    ))
  }
  return (
    <Loader loading={taggedCommentsData.loading} error={taggedCommentsData.error}>
      <div className="tagged-comment-list">
        <LazyLoadDiv
          className="card-list scrollbar"
          id="analysis-list"
          total={taggedCommentsData.data.total}
          currentTotal={(commentsData?.data || []).length}
          loadMoreRows={() => loadMoreTaggedComments()}
          height="calc(100vh - 275px)"
        >
          {
        commentsData.total === 0 ? <div className="no-data-found-msg">No Comments Tagged</div>
          : commentsData.length !== 0 && commentsData?.data.map((item) => (
            <div className="tagged-comment-card">
              <div className="tagged-comment-header">
                <div className="tagged-comment-avtar">
                  <Avatar
                    style={{
                      backgroundColor: '#8A0050',
                    }}
                  >
                    {!item?.tagged_by_user_name ? item?.tagged_by_user_name : ''}
                  </Avatar>
                </div>
                <div className="tagged-comment-info">
                  <div className="tagged-comment-name">
                    {item?.tagged_by_user_name}
                  </div>
                  <div className="tagged-comment-date">
                    {getFormattedDate(item.created_on)}
                  </div>
                </div>
              </div>
              <div className="tagged-comment-contain">
                {item.tagged_user_names ? ReactHtmlParser(getContent(item)) : item.comment}
              </div>
              <div className="tagged-comment-action">
                {getActionItems(item)}
              </div>
            </div>
          ))
}
        </LazyLoadDiv>
      </div>
    </Loader>
  )
}

export default TaggedComments
