import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux'

const UnAuthRoute = ({ altRoute, ...rest }) => {
  const session = useSelector((state) => state.session)
  if (!session) {
    return <Route {...rest} />
  }
  return <Redirect to={{ pathname: altRoute || process.env.redirectRoute }} />
}

UnAuthRoute.propTypes = {
  altRoute: PropTypes.string,
}

UnAuthRoute.defaultProps = {
  altRoute: '',
}

export default UnAuthRoute
